.c-get-ready {
    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: 576px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media (min-width: 768px) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    @media (min-width: 992px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media (min-width: 1200px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    @media (min-width: 1600px) {
        padding-top: 28px;
        padding-bottom: 28px;
    }


    &__container {
    }

    &__text {
        color: white;
        text-align: center;
        font-size: 16px;

        &--detail {
            margin: 0px auto 0;
            padding-bottom: 50px;

            @media (min-width: 576px) {
                margin: 0px auto;
                padding-bottom: 60px;
            }
            @media (min-width: 768px) {
                max-width: 520px;
                margin: 0px auto;
                padding-bottom: 65px;
            }
            @media (min-width: 992px) {
                max-width: 670px;
                margin: 0px auto;
                padding-bottom: 70px;
            }
            @media (min-width: 1200px) {
                max-width: 740px;
                margin: 0px auto;
                padding-bottom: 75px;
            }
            @media (min-width: 1600px) {
                max-width: 820px;
                margin: 0px auto;
                padding-bottom: 80px;
            }
        }
    }

    &__table {
        background: white;
        border-radius: 5px;
        padding: 22px 40px;
        margin-top: 16px;
        margin-bottom: 16px;
        position: relative;
        z-index: 3;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 576px) {
            font-size: 16px;
            padding: 22px 120px;
            margin-top: 18px;
            margin-bottom: 18px;
            max-width: unset;
        }
        @media (min-width: 768px) {
            font-size: 14px;
            padding: 32px 20px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
        @media (min-width: 992px) {
            font-size: 16px;
            padding: 52px 60px;
            margin-bottom: 22px;
        }
        @media (min-width: 1200px) {
            padding: 62px 70px;
            margin-bottom: 24px;
        }
        @media (min-width: 1600px) {
            padding: 72px 120px;
            margin-bottom: 24px;
        }
    }

    &__info-list {
        min-height: 290px;

        @media (min-width: 576px) {
            min-height: 300px;
        }
        @media (min-width: 768px) {
            min-height: 314px;
        }
        @media (min-width: 992px) {
            min-height: 340px;
        }
        @media (min-width: 1200px) {
            min-height: 375px;
        }
        @media (min-width: 1600px) {
            min-height: 382px;
        }
    }

    &__about-now {
        padding-top: 20px;
        font-size: 14px;

        @media (min-width: 576px) {
            padding-top: 28px;
            font-size: 16px;
        }
        @media (min-width: 768px) {
            padding-top: 12px;
            font-size: 14px;
        }
        @media (min-width: 992px) {
            padding-top: 20px;
            font-size: 16px;
        }
        @media (min-width: 1200px) {
            padding-top: 28px;
            font-size: 18px;
        }
        @media (min-width: 1600px) {
            padding-top: 36px;
            font-size: 20px;
        }
    }

    &__image-about-now {
        width: 27px;

        @media (min-width: 576px) {
            width: 30px;
        }
        @media (min-width: 768px) {
            width: 27px;
        }
        @media (min-width: 992px) {
            width: 30px;
        }
        @media (min-width: 1200px) {
            width: 33px;
        }
        @media (min-width: 1600px) {
            width: 36px;
        }
    }

    &__image {
        width: 180px;

        @media (min-width: 576px) {
            width: 200px;
        }
        @media (min-width: 768px) {
            width: 160px;
        }
        @media (min-width: 992px) {
            width: 200px;
        }
        @media (min-width: 1200px) {
            width: 240px;
        }
        @media (min-width: 1600px) {
            width: 260px;
        }
    }

    &__list-ul {
        text-align: left;
    }

    &__list-li {
        font-size: 14px;

        @media (min-width: 576px) {
            font-size: 16px;
        }
        @media (min-width: 768px) {
            font-size: 14px;
        }
        @media (min-width: 992px) {
            font-size: 16px;
        }
        @media (min-width: 1200px) {
            font-size: 18px;
        }
        @media (min-width: 1600px) {
            font-size: 20px;
        }

        &::marker {
            color: #5AFF7A;
            line-height: 0.8;
            text-align: end !important;
            text-align-last: end !important;
            font-size: 20px;

            @media (min-width: 576px) {
                font-size: 22px;
            }
            @media (min-width: 768px) {
                font-size: 24px;
            }
            @media (min-width: 992px) {
                font-size: 26px;
            }
            @media (min-width: 1200px) {
                font-size: 28px;
            }
            @media (min-width: 1600px) {
                font-size: 30px;
            }
        }

        &--false {
            &::marker {
                color: #FF1105;
            }
        }
    }

    &__about-now-ul {
        list-style: none;
        padding-inline-start: 0;
        display:flex;
        justify-content: center;
    }

    &__about-now-li {
        float: right;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;

        @media (min-width: 576px) {
            padding-left: 24px;
            padding-right: 32px;
        }
        @media (min-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media (min-width: 992px) {
            padding-left: 24px;
            padding-right: 24px;
        }
        @media (min-width: 1200px) {
            padding-left: 28px;
            padding-right: 28px;
        }
        @media (min-width: 1600px) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}