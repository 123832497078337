.c-register {
    padding-top: 40px;
    padding-bottom: 40px;

    &__text-title {
        color: #003936;
        text-align: center;
        margin: 0px auto 40px;
        max-width: 266px;
        font-size: 28px;

        @media (min-width: 576px) {
            font-size: 36px;
            max-width: 336px;
            margin: 0px auto 52px;
        }
        @media (min-width: 768px) {
            max-width: 100%;
            margin: 0px auto 20px;
            color: white;
            font-size: 44px;
        }
        @media (min-width: 992px) {
            font-size: 52px;
        }
        @media (min-width: 1200px) {
            font-size: 60px;
        }
        @media (min-width: 1600px) {
            font-size: 68px;
        }

        & span {
            color: #00FD66;
        }
    }

    &__text-subtitle {
        color: #003936;
        text-align: center;
        font-size: 18px;
        max-width: 266px;
        margin: 0 auto;
        line-height: 1.5;

        @media (min-width: 576px) {
            font-size: 22px;
            max-width: 336px;
        }
        @media (min-width: 768px) {
            max-width: 100%;
            color: white;
            font-size: 25px;
        }
        @media (min-width: 992px) {
            font-size: 30px;
        }
        @media (min-width: 1200px) {
            font-size: 35px;
        }
        @media (min-width: 1600px) {
            font-size: 40px;
        }

        & span {
            color: #00FD66;
        }
    }

    &__form {
        max-width: 400px;
        padding-top: 72px;
        padding-bottom: 72px;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: 576px) {
            max-width: 500px;
            padding-top: 64px;
            padding-bottom: 64px;
        }
        @media (min-width: 768px) {}
        @media (min-width: 992px) {}
        @media (min-width: 1200px) {}
        @media (min-width: 1600px) {
            max-width: 1200px;
        }
    }

    &__control {
        background: white;
        font-size: 16px;
        padding: 4px 8px 6px;
        border: 2px solid #759484;
        color: #003936;
        border-radius: 10px;
        width: 100%;
        text-align: center;
        margin-bottom: 16px;

        @media (min-width: 576px) {
            font-size: 20px;
            padding: 4px 12px 10px;
            margin-bottom: 24px;
        }
        @media (min-width: 768px) {
            background: #001f1a80;
            text-align: left;
            color: white;
            max-width: 500px;
            margin-right: auto;
            margin-left: auto;
        }
        @media (min-width: 992px) {}
        @media (min-width: 1200px) {}
        @media (min-width: 1600px) {}

        &::placeholder {
            color: #003936;

            @media (min-width: 768px) {
                color: white;
            }
        }

        &:focus {
            border-color: #759484;
            box-shadow: 0 0 0 0.25rem rgb(117 148 132 / 25%);
            background: white;
            color: #003936;
            
            @media (min-width: 768px) {
                background: #0b100f;
                color: white;
            }
            @media (min-width: 992px) {}
            @media (min-width: 1200px) {}
            @media (min-width: 1600px) {}
        }
    }
}