.o-font {
    &__text-descript {
        font-size: 14px;
        margin: 0;
        color: white;
        text-align: right;
        line-height: 24px;

        &--center {
            text-align: center;
        }
        // @media (min-width: 576px) {
        //     font-size: 22px;
        //     padding-bottom: 28px;
        // }
        // @media (min-width: 768px) {
        //     font-size: 25px;
        //     padding-bottom: 34px;
        // }
        // @media (min-width: 992px) {
        //     font-size: 22px;
        //     padding-bottom: 40px;
        // }
        // @media (min-width: 1200px) {
        //     font-size: 25px;
        //     padding-bottom: 46px;
        // }
        // @media (min-width: 1600px) {
        //     font-size: 28px;
        //     padding-bottom: 52px;
        // }
    }
    &__section-test {
        font-size: 24px;
        margin: 0;

        &--verde {
            color: #5aff7a;
        }

        &--position {
            text-align: center;
            @media (min-width: 768px) {
                text-align: end;
            }
        }

        @media (min-width: 768px) {
            font-size: 25px;
            padding-bottom: 34px;
        }
    }

    &__small-title-green {
        color: #5aff7a;
        text-align: center;
        font-size: 19px;
        padding-bottom: 22px;
        margin: 0;

        @media (min-width: 576px) {
            font-size: 22px;
            padding-bottom: 28px;
        }
        @media (min-width: 768px) {
            font-size: 25px;
            padding-bottom: 34px;
        }
        @media (min-width: 992px) {
            font-size: 22px;
            padding-bottom: 40px;
        }
        @media (min-width: 1200px) {
            font-size: 25px;
            padding-bottom: 46px;
        }
        @media (min-width: 1600px) {
            font-size: 28px;
            padding-bottom: 52px;
        }
    }

    &__tall-subtitle {
        text-align: center;
        font-size: 26px;
        line-height: 1.25;

        &--white {
            color: white;
        }

        &--green {
            color: #5aff7a;
        }

        @media (min-width: 576px) {
            font-size: 46px;
        }
        @media (min-width: 768px) {
            font-size: 32px;
            line-height: 1.5;
        }
        @media (min-width: 992px) {
            font-size: 44px;
        }
        @media (min-width: 1200px) {
            font-size: 56px;
        }
        @media (min-width: 1600px) {
            font-size: 68px;
        }
    }

    &__paragraph-white {
        color: white;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        line-height: 1.8;

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 14px;
            line-height: 1.5;
            padding-left: 0px;
            padding-right: 0px;
        }
        @media (min-width: 992px) {
            font-size: 18px;
        }
        @media (min-width: 1200px) {
            font-size: 20px;
        }
        @media (min-width: 1600px) {
            font-size: 22px;
        }
    }

    &__get-ready-title {
        color: black;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 0;

        font-size: 14px;

        @media (min-width: 576px) {
            font-size: 16px;
        }
        @media (min-width: 768px) {
            font-size: 14px;
        }
        @media (min-width: 992px) {
            font-size: 16px;
        }
        @media (min-width: 1200px) {
            font-size: 18px;
        }
        @media (min-width: 1600px) {
            font-size: 20px;
        }
    }

    &__control-calculator-same {
        color: white;
        font-size: 40px;
        padding-top: 60px;
        margin-bottom: 0;

        @media (min-width: 576px) {
            padding-top: 64px;
        }
        @media (min-width: 768px) {
            padding-top: 42px;
        }
        @media (min-width: 992px) {
            padding-top: 68px;
        }
        @media (min-width: 1200px) {
            padding-top: 76px;
        }
        @media (min-width: 1600px) {
            padding-top: 80px;
        }
    }

    &__tall-black-title {
        color: black;
        font-size: 26px;

        @media (min-width: 576px) {
            font-size: 46px;
        }
        @media (min-width: 768px) {
            font-size: 32px;
            line-height: 1.5;
        }
        @media (min-width: 992px) {
            font-size: 44px;
        }
        @media (min-width: 1200px) {
            font-size: 56px;
        }
        @media (min-width: 1600px) {
            font-size: 68px;
        }
    }

    &__p-menu-dropdown {
        margin-bottom: 0px;
        font-size: 14px;

        @media (min-width: 576px) {
            font-size: 16px;
        }
    }

    &__header-link {
        font-family: Majorant-Th;
        color: white;
        text-decoration: none;

        &:after {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            content: "";
            display: block;
            width: 0px;
            height: 3px;
            transition: all 300ms ease;
            text-align: center;
            opacity: 1;
            // background: rgba(0, 253, 101, 1);
            // background: -moz-linear-gradient(left, rgba(0, 253, 101, 1) 0%, rgba(0, 180, 102, 1) 100%);
            // background: -webkit-gradient(
            //     left top,
            //     right top,
            //     color-stop(0%, rgba(0, 253, 101, 1)),
            //     color-stop(100%, rgba(0, 180, 102, 1))
            // );
            // background: -webkit-linear-gradient(left, rgba(0, 253, 101, 1) 0%, rgba(0, 180, 102, 1) 100%);
            // background: -o-linear-gradient(left, rgba(0, 253, 101, 1) 0%, rgba(0, 180, 102, 1) 100%);
            // background: -ms-linear-gradient(left, rgba(0, 253, 101, 1) 0%, rgba(0, 180, 102, 1) 100%);
            // background: linear-gradient(to right, rgba(0, 253, 101, 1) 0%, rgba(0, 180, 102, 1) 100%);
        }

        &:hover,
        &.active {
            &::after {
                width: 100%;
            }
        }
    }

    &__footer-nav-title {
        line-height: 1.5;
        font-size: 15px;

        @media (min-width: 576px) {
            font-size: 18px;
        }
        @media (min-width: 768px) {
            font-size: 16px;
        }
        @media (min-width: 992px) {
            font-size: 18px;
        }
        @media (min-width: 1200px) {
            font-size: 21px;
        }
        @media (min-width: 1600px) {
            font-size: 24px;
        }
    }

    &__footer-link {
        line-height: 1;
        font-size: 13px;

        &--mailto {
            font-size: 16px;

            @media (min-width: 576px) {
                font-size: 14px;
            }
            @media (min-width: 768px) {
                font-size: 11px;
            }
            @media (min-width: 992px) {
                font-size: 14px;
            }
            @media (min-width: 1200px) {
                font-size: 18px;
            }
            @media (min-width: 1600px) {
                font-size: 22px;
            }
        }

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 11px;
        }
        @media (min-width: 992px) {
            font-size: 13px;
        }
        @media (min-width: 1200px) {
            font-size: 18px;
        }
        @media (min-width: 1600px) {
            font-size: 22px;
        }
    }

    &__footer-form-title {
        font-size: 14px;
        line-height: 1.3;

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 11px;
            line-height: 1.5;
        }
        @media (min-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 1200px) {
            font-size: 16px;
            line-height: 1.2;
        }
        @media (min-width: 1600px) {
            font-size: 23px;
        }
    }

    &__form-control {
        font-size: 1rem;

        @media (min-width: 576px) {
            font-size: 1.25rem;
        }
        @media (min-width: 768px) {
            font-size: 1rem;
        }
        @media (min-width: 992px) {
            font-size: 0.85rem;
        }
        @media (min-width: 1200px) {
            font-size: 1rem;
        }
        @media (min-width: 1600px) {
            font-size: 1.25rem;
        }
    }

    &__timing-time {
        color: black;
        padding-top: 8px;
        margin-bottom: 0;
        font-size: 25px;

        @media (min-width: 576px) {
            font-size: 28px;
        }
        @media (min-width: 768px) {
            font-size: 31px;
        }
        @media (min-width: 992px) {
            font-size: 34px;
        }
        @media (min-width: 1200px) {
            font-size: 37px;
        }
        @media (min-width: 1600px) {
            font-size: 40px;
        }
    }

    &__timing-description {
        color: black;
        font-size: 8px;
        padding-top: 8px;
        margin-bottom: 0;

        @media (min-width: 576px) {
            font-size: 12px;
        }
        @media (min-width: 768px) {
            font-size: 13px;
        }
        @media (min-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 1200px) {
            font-size: 15px;
        }
        @media (min-width: 1600px) {
            font-size: 16px;
        }
    }

    &__timing-time-title {
        color: black;
        font-size: 34px;
        padding-top: 8px;
        margin-bottom: 0;

        @media (min-width: 576px) {
            font-size: 12px;
        }
        @media (min-width: 768px) {
            font-size: 13px;
        }
        @media (min-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 1200px) {
            font-size: 15px;
        }
        @media (min-width: 1600px) {
            font-size: 16px;
        }
    }

    &__timing-time-subtitle {
        color: black;
        font-size: 12px;
        padding-top: 25px;
        margin-bottom: 0;

        @media (min-width: 576px) {
            font-size: 16px;
            padding-top: 28px;
        }
        @media (min-width: 768px) {
            font-size: 10px;
            padding-top: 31px;
        }
        @media (min-width: 992px) {
            font-size: 12px;
            padding-top: 28px;
        }
        @media (min-width: 1200px) {
            font-size: 15px;
            padding-top: 30px;
        }
        @media (min-width: 1600px) {
            font-size: 16px;
            padding-top: 40px;
        }
    }
}
