.img-video {
    width: 90% !important;
    margin: 0 auto;
}

.c-videos {
    background-color: #001917;
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @media (min-width: 576px) {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
    @media (min-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    @media (min-width: 992px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    @media (min-width: 1200px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    @media (min-width: 1600px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    &__text {
        color: #ffffff;
        font-family: Majorant-Regular;
        line-height: 1.25;
        font-size: 1rem;
        margin-bottom: .5rem;

        &--title {
            font-family: Majorant-Blk;
            font-size: 3.6rem;
            padding-bottom: 0.75rem;
            text-align: center;
    
            @media (min-width: 576px) {
                font-size: 4.25rem;
                padding-bottom: 1rem;
            }
            @media (min-width: 768px) {
                font-size: 6rem;
                padding-bottom: 1.25rem;
            }
            @media (min-width: 992px) {
                font-size: 3.75rem;
                padding-bottom: 0.75rem;
            }
            @media (min-width: 1200px) {
                font-size: 4.25rem;
                padding-bottom: 1rem;
            }
            @media (min-width: 1600px) {
                font-size: 6rem;
                padding-bottom: 1.25rem;
            }
        }
    }

    & .owl-prev,
    & .owl-next {
        height: 45px !important;
    }
}