.c-owl-carousel {

    @media (max-width: 576px) {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
    }

    &--padding {
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media (min-width: 576px) {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
        @media (min-width: 768px) {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        @media (min-width: 992px) {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        @media (min-width: 1200px) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
        @media (min-width: 1600px) {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }

    &__item {
        margin-left: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
    }

    & .owl-stage {
        display: flex;
        align-items: center;
    }

    & .owl-theme {

        & .owl-nav {
            margin-top: 0;
            position: absolute;
            width: 100%;
            top: 30%;
            left: 0;
            transform: translate(0%, -50%);

            @media (min-width: 576px) {
                top: 33%;
            }
            @media (min-width: 768px) {
                top: 40%;
            }
            @media (min-width: 992px) {
                top: 38%;
            }
            @media (min-width: 1200px) {
                top: 40%;
            }
            @media (min-width: 1600px) {
                top: 40%;
            }

            & .owl-prev {
                position: absolute;
                left: -30px;
                width: 28px;
                height: 28px;

                @media (min-width: 576px) {
                    left: -44px;
                    width: 32px;
                    height: 32px;
                }
                @media (min-width: 768px) {
                    left: -36px;
                    width: 28px;
                    height: 28px;
                }
                @media (min-width: 992px) {
                    left: -44px;
                    width: 32px;
                    height: 32px;
                }
                @media (min-width: 1200px) {
                    left: -52px;
                    width: 36px;
                    height: 36px;
                }
                @media (min-width: 1600px) {
                    left: -60px;
                    width: 40px;
                    height: 40px;
                }

                & span {
                    font-size: 34px;
                    line-height: 0.5;
                    color: white;
                    
                    @media (min-width: 576px) {
                        font-size: 36px;
                    }
                    @media (min-width: 768px) {
                        font-size: 38px;
                    }
                    @media (min-width: 992px) {
                        font-size: 40px;
                    }
                    @media (min-width: 1200px) {
                        font-size: 42px;
                    }
                    @media (min-width: 1600px) {
                        font-size: 46px;
                    }
                }
            }

            & .owl-next {
                position: absolute;
                right: -30px;
                width: 28px;
                height: 28px;

                @media (min-width: 576px) {
                    right: -44px;
                    width: 32px;
                    height: 32px;
                }
                @media (min-width: 768px) {
                    right: -36px;
                    width: 28px;
                    height: 28px;
                }
                @media (min-width: 992px) {
                    right: -44px;
                    width: 32px;
                    height: 32px;
                }
                @media (min-width: 1200px) {
                    right: -52px;
                    width: 36px;
                    height: 36px;
                }
                @media (min-width: 1600px) {
                    right: -60px;
                    width: 40px;
                    height: 40px;
                }

                & span {
                    font-size: 34px;
                    line-height: 0.5;
                    color: white;
                    
                    @media (min-width: 576px) {
                        font-size: 36px;
                    }
                    @media (min-width: 768px) {
                        font-size: 38px;
                    }
                    @media (min-width: 992px) {
                        font-size: 40px;
                    }
                    @media (min-width: 1200px) {
                        font-size: 42px;
                    }
                    @media (min-width: 1600px) {
                        font-size: 46px;
                    }
                }
            }
        }
    }

    &.owl-holding {
        &--padding {
            padding-left: 8px;
            padding-right: 8px;
    
            @media (min-width: 576px) {
                padding-left: 16px;
                padding-right: 16px;
            }
            @media (min-width: 768px) {
                padding-left: 12px;
                padding-right: 12px;
            }
            @media (min-width: 992px) {
                padding-left: 16px;
                padding-right: 16px;
            }
            @media (min-width: 1200px) {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media (min-width: 1600px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    
        &__item {
            margin-left: 5px;
            margin-right: 5px;
            display: flex;
            align-items: center;
        }
    
        & .owl-stage {
            display: flex;
            align-items: center;
        }
    
        & .owl-theme {
    
            & .owl-nav {
                margin-top: 0;
                position: absolute;
                width: 100%;
                top: 25%;
                left: 0;
    
                & .owl-prev {
                    position: absolute;
                    left: -30px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    @media (min-width: 576px) {
                        left: -44px;
                        width: 32px;
                        height: 32px;
                    }
                    @media (min-width: 768px) {
                        left: -36px;
                        width: 28px;
                        height: 28px;
                    }
                    @media (min-width: 992px) {
                        left: -44px;
                        width: 32px;
                        height: 32px;
                    }
                    @media (min-width: 1200px) {
                        left: -52px;
                        width: 36px;
                        height: 36px;
                    }
                    @media (min-width: 1600px) {
                        left: -60px;
                        width: 40px;
                        height: 40px;
                    }
    
                    & span {
                        font-size: 34px;
                        line-height: 0.5;
                        display: contents;
                        color: #003936;
                        
                        @media (min-width: 576px) {
                            font-size: 36px;
                        }
                        @media (min-width: 768px) {
                            font-size: 38px;
                        }
                        @media (min-width: 992px) {
                            font-size: 40px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 42px;
                        }
                        @media (min-width: 1600px) {
                            font-size: 46px;
                        }
                    }
                }
    
                & .owl-next {
                    position: absolute;
                    right: -30px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    @media (min-width: 576px) {
                        right: -44px;
                        width: 32px;
                        height: 32px;
                    }
                    @media (min-width: 768px) {
                        right: -36px;
                        width: 28px;
                        height: 28px;
                    }
                    @media (min-width: 992px) {
                        right: -44px;
                        width: 32px;
                        height: 32px;
                    }
                    @media (min-width: 1200px) {
                        right: -52px;
                        width: 36px;
                        height: 36px;
                    }
                    @media (min-width: 1600px) {
                        right: -60px;
                        width: 40px;
                        height: 40px;
                    }
    
                    & span {
                        font-size: 34px;
                        line-height: 0.5;
                        display: contents;
                        color: #003936;
                        
                        @media (min-width: 576px) {
                            font-size: 36px;
                        }
                        @media (min-width: 768px) {
                            font-size: 38px;
                        }
                        @media (min-width: 992px) {
                            font-size: 40px;
                        }
                        @media (min-width: 1200px) {
                            font-size: 42px;
                        }
                        @media (min-width: 1600px) {
                            font-size: 46px;
                        }
                    }
                }
            }
        }
    }
}



.owl-theme .owl-nav [class*='owl-']:hover {
    border: 1px solid #003936;
    background: #ffffff2e;
}