.c-token {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;
    background-color: #ffffff;

    @media (min-width: 576px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    @media (min-width: 768px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    @media (min-width: 992px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    @media (min-width: 1200px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    @media (min-width: 1600px) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    &__container {
    }

    &__detail {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        line-height: 1.4;
        width: 50%;
        padding: 1.5rem 0rem;

        @media (min-width: 576px) {
            padding: 2.25rem 6rem;
        }
        @media (min-width: 768px) {
            padding: 2.25rem 6rem;
        }
        @media (min-width: 992px) {
            padding: 3.25rem 6rem;
        }
        @media (min-width: 1200px) {
            padding: 4rem 8rem;
        }
        @media (min-width: 1600px) {
            padding: 5rem 13rem;
        }

        &--token {
            width: 960px;
        }

        &--contract {
            z-index: 10;
            position: relative;
            border-radius: 40px;
            width: 200px;
            background: #003936;

            @media (min-width: 576px) {
                border-radius: 60px;
                width: 350px;
            }
            @media (min-width: 768px) {
                border-radius: 80px;
                width: 650px;
                border-radius: 9999px;
            }
            @media (min-width: 992px) {
                width: 850px;
            }
            @media (min-width: 1200px) {
                width: 1060px;
            }
            @media (min-width: 1600px) {
                width: 1350px;
            }
        }

        &--contract-text {
            z-index: 10;
            position: relative;
            
            @media (min-width: 768px) {
                width: 676px;
                border-radius: 9999px;
            }
            @media (min-width: 992px) {
                width: 876px;
            }
            @media (min-width: 1200px) {
                width: 1086px;
            }
            @media (min-width: 1600px) {
                width: 1375px;
            }
        }
    }

    &__img-americoano-futbol {
        width: 60%;
        max-width: 180px;
        position: relative;
        transform: translateY(12%);

        @media (min-width: 576px) {
            max-width: unset;
            width: 60%;
            transform: translateY(10%);
        }
        @media (min-width: 768px) {
            transform: translateY(0%);
            position: absolute;
            width: 96%;
            top: 2.5%;
            left: 9%;
        }
        @media (min-width: 992px) {
            width: 94%;
            top: -4.8%;
            left: 8%;
        }
        @media (min-width: 1200px) {
            width: 96%;
            top: -0.5%;
            left: 5%;
        }
        @media (min-width: 1600px) {
            top: -3%;
            left: 4%;
        }
    }

    &__text {
        color: #000000;
        text-align: center;
        margin-bottom: 0;
        font-size: 1rem;

        &--title {
            font-family: Majorant-Blk;
            text-align: center;
            font-size: 26px;
            line-height: 1.25;

            @media (min-width: 576px) {
                font-size: 1.75rem;
            }
            @media (min-width: 768px) {
                text-align: right;
                margin-right: 1.5rem;
                font-size: 2rem;
            }
            @media (min-width: 992px) {
                margin-right: 2rem;
                font-size: 2.5rem;
            }
            @media (min-width: 1200px) {
                margin-right: 2.7rem;
                font-size: 3.3rem;
            }
            @media (min-width: 1600px) {
                margin-right: 3rem;
                font-size: 4.25rem;
            }
        }

        &--detail {
            font-family: Majorant-Regular;
            text-align: center;
            line-height: 1.25;
            font-size: 0.85rem;
            margin: 1.25rem 0;
            
            @media (min-width: 576px) {
                font-size: 1rem;
                margin: 2rem 0;
            }
            @media (min-width: 768px) {
                text-align: right;
                font-size: .75rem;
                margin: 1.75rem 1.5rem 1.75rem 0;
            }
            @media (min-width: 992px) {
                font-size: 0.95rem;
                margin: 1.75rem 2rem 1.75rem 0;
            }
            @media (min-width: 1200px) {
                font-size: 1.25rem;
                margin: 2.25rem 2.7rem 2.25rem 0;
            }
            @media (min-width: 1600px) {
                font-size: 1.6rem;
                margin: 2.5rem 3rem 2.5rem 0;
            }
        }

        &--contract-title {
            font-family: Majorant-Th;
            color: #ffffff;
            line-height: 1.5;
            font-size: 1.25rem;
            
            @media (min-width: 576px) {
                font-size: 1.75rem;
            }
            @media (min-width: 768px) {
                font-size: 1rem;
                line-height: 1.25;
                padding-bottom: 0.25rem;
            }
            @media (min-width: 992px) {
                font-size: 1.25rem;
                padding-bottom: 0.3rem;
            }
            @media (min-width: 1200px) {
                font-size: 1.75rem;
                padding-bottom: 0.4rem;
            }
            @media (min-width: 1600px) {
                font-size: 2rem;
                padding-bottom: 0.5rem;
            }
        }

        &--contract-subtitle {
            font-family: Majorant-Medium;
            color: #03fb66;
            line-height: 1.25;
            font-size: 1.5rem;
            
            @media (min-width: 576px) {
                font-size: 2rem;
            }
            @media (min-width: 768px) {
                font-size: 1.15rem;
            }
            @media (min-width: 992px) {
                font-size: 1.3rem;
            }
            @media (min-width: 1200px) {
                font-size: 1.75rem;
                line-height: 1.25;
            }
            @media (min-width: 1600px) {
                font-size: 2rem;
            }
        }
    }
}
