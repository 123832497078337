.c-project {
    width: 100%;
    padding-top: 38px;
    padding-bottom: 38px;

    @media (min-width: 576px) {
        padding-top: 42px;
        padding-bottom: 42px;
    }
    @media (min-width: 768px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    @media (min-width: 992px) {
        padding-top: 52px;
        padding-bottom: 52px;
    }
    @media (min-width: 1200px) {
        padding-top: 58px;
        padding-bottom: 58px;
    }
    @media (min-width: 1600px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    &__container {
    }

    &__asterisks-pattern {
        position: absolute;
        width: 428px;
        z-index: 1;
        display: none;

        @media (min-width: 768px) {
            display: block;
            width: 260px;
            bottom: 0px;
            left: -40px;
        }
        @media (min-width: 992px) {
            width: 300px;
            bottom: -20px;
            left: -40px;
        }
        @media (min-width: 1200px) {
            width: 340px;
            bottom: -40px;
            left: -70px;
        }
        @media (min-width: 1600px) {
            width: 380px;
            bottom: -60px;
            left: 20px;
        }
    }

    &__detail {
        background: white;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.4;
        border-radius: 5px;
        padding: 28px 18px;
        z-index: 20;
        width: 280px;
        margin-top: 0px;
        margin-bottom: 0px;

        @media (min-width: 576px) {
            padding: 20px 36px;
            width: 100%;
        }
        @media (min-width: 768px) {
            width: 600px;
            padding: 48px 18px;
        }
        @media (min-width: 992px) {
            width: 800px;
            padding: 56px 52px;
        }
        @media (min-width: 1200px) {
            width: 1000px;
            padding: 64px 60px;
        }
        @media (min-width: 1600px) {
            width: 1180px;
            padding: 72px 68px;
        }
    }

    &__separator {
        background: linear-gradient(to right, #00FD66, #00B466);
        opacity: 1;
        margin-top: 28px;
        margin-bottom: 6px;
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        
        &:not([size]) {
            height: 3px;
        }

        @media (min-width: 576px) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        @media (min-width: 768px) {
            margin-top: 26px;
            margin-bottom: 26px;
        }
        @media (min-width: 992px) {
            margin-top: 32px;
            margin-bottom: 32px;
        }
        @media (min-width: 1200px) {
            margin-top: 48px;
            margin-bottom: 48px;
        }
        @media (min-width: 1600px) {
            margin-top: 64px;
            margin-bottom: 64px;
        }
    }

    &__text {
        color: black;
        text-align: center;
        font-size: 13px;

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 15px;
        }
        @media (min-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 1200px) {
            font-size: 15px;
        }
        @media (min-width: 1600px) {
            font-size: 16px;
        }
        
        &--title {
            line-height: 1.3;
            font-size: 20px;
            max-width: 258px;
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 576px) {
                line-height: 1.4;
                font-size: 46px;
                max-width: unset;
                margin-left: unset;
                margin-right: unset;
            }
            @media (min-width: 768px) {
                font-size: 32px;
            }
            @media (min-width: 992px) {
                font-size: 44px;
            }
            @media (min-width: 1200px) {
                font-size: 56px;
            }
            @media (min-width: 1600px) {
                font-size: 64px;
            }
        }

        &--subtitle {
            color: #5AFF7A;
            font-size: 19px;
            
            @media (min-width: 576px) {
                font-size: 22px;
            }
            @media (min-width: 768px) {
                font-size: 25px;
            }
            @media (min-width: 992px) {
                font-size: 22px;
            }
            @media (min-width: 1200px) {
                font-size: 25px;
            }
            @media (min-width: 1600px) {
                font-size: 28px;
            }
        }
        
        &--description {
            padding: 0;
            color: #001F1A;
            padding-top: 12px;
            font-size: 13px;
            max-width: 242px;
            margin-left: auto;
            margin-right: auto;
            
            @media (min-width: 576px) {
                font-size: 22.4px;
                max-width: unset;
                margin-left: unset;
                margin-right: unset;
                padding: 0 16px;
            }
            @media (min-width: 768px) {
                font-size: 12px;
                padding: 0 8px;
            }
            @media (min-width: 992px) {
                font-size: 14.8px;
            }
            @media (min-width: 1200px) {
                font-size: 18.8px;
            }
            @media (min-width: 1600px) {
                font-size: 22.4px;
            }
        }
    }
}