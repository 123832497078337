.c-calculator {
    position: relative;
    z-index: 3;
    padding-top: 12px;
    padding-bottom: 12px;
    max-width: 330px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        padding-top: 16px;
        padding-bottom: 16px;
        max-width: 412px;
    }
    @media (min-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: unset;
    }
    @media (min-width: 992px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    @media (min-width: 1200px) {
        padding-top: 28px;
        padding-bottom: 28px;
    }
    @media (min-width: 1600px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    &__form {
    }

    &__control {
        line-height: 1;
        text-align: right;
        padding: 0rem 1rem 0.3rem;
        color: black;
        font-size: 20px;

        @media (min-width: 576px) {
            font-size: 24px;
        }
        @media (min-width: 768px) {
            font-size: 22px;
        }
        @media (min-width: 992px) {
            font-size: 30px;
        }
        @media (min-width: 1200px) {
            font-size: 33px;
        }
        @media (min-width: 1600px) {
            font-size: 36px;
        }
    }

    &__label {
        color: white;
        text-align: center;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 0px;
        font-size: 24px;

        @media (min-width: 576px) {
            font-size: 24px;
            padding-top: 12px;
            padding-bottom: 4px;
        }
        @media (min-width: 768px) {
            font-size: 22px;
            padding-top: 0px;
            padding-bottom: 10px;
        }
        @media (min-width: 992px) {
            font-size: 30px;
            padding-bottom: 16px;
        }
        @media (min-width: 1200px) {
            font-size: 33px;
            padding-bottom: 18px;
        }
        @media (min-width: 1600px) {
            font-size: 36px;
            padding-bottom: 20px;
        }    
    }
}