.c-modals {

    &__register {
        z-index: 9998;

        & .modal-dialog {
            max-width: 1600px;
        }
        
        & .modal-content {
            background: url(../../assets/images/modals/backbrod-register.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 5px;

            & .modal-header {
                border-bottom: 0;
            }

            & .modal-body {
                padding: 2rem 1rem 4rem;

                @media (min-width: 576px) {
                    padding: 3rem 1rem 7rem;
                }
                @media (min-width: 768px) {
                    padding: 3.5rem 1rem 8rem;
                }
                @media (min-width: 992px) {
                    padding: 4rem 1rem 9rem;
                }
                @media (min-width: 1200px) {
                    padding: 4.5rem 1rem 10rem;
                }
                @media (min-width: 1600px) {
                    padding: 1rem 1rem 3rem;
                }
            }
        }

        & .close {
            background: transparent;
            color: black;
            font-size: 20px;

            @media (max-width: 767px) {
                color: white;
            }
        }
    }

    &__notification {
        z-index: 9999;
        padding-top: 15px;

        &-error {
            color: red;
            background-color: white;
            border: 2px solid #759484;
            border-radius: 5px;
            position: relative;
            padding: 2px 15px 5px;
            margin-top: 12px;
        }
        &-succes {
            color: green;
            background-color: white;
            border: 2px solid #759484;
            border-radius: 5px;
            position: relative;
            padding: 2px 15px 5px;
            margin-top: 12px;
        }
    }
}