.c-last-div {
    width: 100%;
    background: #03fb66;

    &__box {
        height: 4rem;
    
        @media (min-width: 576px) {
            height: 5rem;
        }
        @media (min-width: 768px) {
            height: 4rem;
        }
        @media (min-width: 992px) {
            height: 4rem;
        }
        @media (min-width: 1200px) {
            height: 6rem;
        }
        @media (min-width: 1600px) {
            height: 8rem;
        }
    }
}