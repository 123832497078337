.c-challenging {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 98;
    position: relative;
    background-color: #003936;

    @media (min-width: 576px) {
        padding-top: 3rem;
        padding-bottom: 1.5rem;
    }
    @media (min-width: 768px) {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }
    @media (min-width: 992px) {
        padding-top: 5rem;
        padding-bottom: 2.5rem;
    }
    @media (min-width: 1200px) {
        padding-top: 5.5rem;
        padding-bottom: 2.75rem;
    }
    @media (min-width: 1600px) {
        padding-top: 6rem;
        padding-bottom: 3rem;
    }

    &__container {
        @media (max-width: 430px) {
            max-width: 320px;
        }
        @media (max-width: 575px) {
            max-width: 430px;
        }
    }

    &__icon {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        height: 7rem;

        @media (min-width: 576px) {
            margin-bottom: 1rem;
            height: 8rem;
        }
        @media (min-width: 768px) {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
            height: 10rem;
        }
        @media (min-width: 992px) {
            margin-top: 1.5rem;
            margin-bottom: 0;
            height: 8rem;
        }
        @media (min-width: 1200px) {
            margin-top: 1.75rem;
            height: 10rem;
        }
        @media (min-width: 1600px) {
            margin-top: 2rem;
            height: 12rem;
        }
    }

    &__last-div {
        width: 100%;
        height: 2rem;
        background-color: #03fb66;

        @media (min-width: 576px) {
            height: 2.25rem;
        }
        @media (min-width: 768px) {
            height: 2.5rem;
        }
        @media (min-width: 992px) {
            height: 2rem;
        }
        @media (min-width: 1200px) {
            height: 2.25rem;
        }
        @media (min-width: 1600px) {
            height: 2.5rem;
        }
    }

    &__text {
        color: white;
        text-decoration: none;

        &--title {
            font-size: 3rem;
            text-align: center;
            color: #00fd66;
            font-family: Majorant-Blk;

            @media (min-width: 576px) {
                font-size: 3.5rem;
            }
            @media (min-width: 768px) {
                font-size: 2.25rem;
            }
            @media (min-width: 992px) {
                font-size: 2rem;
            }
            @media (min-width: 1200px) {
                font-size: 2.25rem;
            }
            @media (min-width: 1600px) {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
        }

        &--description {
            font-size: .9rem;
            margin-bottom: 20px;
            max-width: 276px;
            margin-right: auto;
            margin-left: auto;

            @media (min-width: 576px) {
                font-size: 1rem;
                margin-bottom: 24px;
                line-height: 1.25;
                max-width: 300px;
            }
            @media (min-width: 768px) {
                font-size: 1.05rem;
                margin-bottom: 28px;
                text-align: left;
                max-width: unset;
            }
            @media (min-width: 992px) {
                font-size: 1.2rem;
                margin-bottom: 32px;
            }
            @media (min-width: 1200px) {
                font-size: 1.35rem;
                margin-bottom: 36px;
            }
            @media (min-width: 1600px) {
                font-size: 1.5rem;
                margin-bottom: 40px;
            }
        }

        &--info-icons {
            font-family: Majorant-Lt;
            text-align: center;
            font-size: 0.75rem;

            @media (min-width: 576px) {
                font-size: 0.85rem;
            }
            @media (min-width: 768px) {
                font-size: 1.25rem;
            }
            @media (min-width: 992px) {
                font-size: 0.75rem;
            }
            @media (min-width: 1200px) {
                font-size: 1rem;
            }
            @media (min-width: 1600px) {
                font-size: 1.25rem;
                height: 3.75rem;
            }
        }
    }

    &__div-icons {
        padding-top: 2rem;

        @media (min-width: 576px) {
            padding-top: 2.5rem;
        }
        @media (min-width: 768px) {
            padding-top: 3rem;
        }
        @media (min-width: 992px) {
            padding-top: 4rem;
        }
        @media (min-width: 1200px) {
            padding-top: 6rem;
        }
        @media (min-width: 1600px) {
            padding-top: 8rem;
        }
    }

    &__space {
        display: block;
        
        @media (min-width: 768px) {
            display: none;
        }
    }

    &__icon-future {
        width: 60%;
        padding-bottom: 24px;
        max-width: 240px;

        @media (min-width: 576px) {
            width: 50%;
            padding-bottom: 28px;
            max-width: 280px;
        }
        @media (min-width: 768px) {
            width: 176px;
            max-width: unset;
        }
        @media (min-width: 1200px) {
            width: 176px;
            max-width: unset;
        }
        @media (min-width: 1600px) {
            width: 176px;
            max-width: unset;
        }
    }

    &__center-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__top-lefth {
        display: flex;
        justify-content: center;
        align-items: end;
        
        @media (min-width: 768px) {
            justify-content: flex-end;
        }
    }

    &__owl {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: 576px) {
            padding-top: 34px;
            padding-bottom: 34px;
        }
        @media (min-width: 768px) {
            padding-top: 56px;
            padding-bottom: 56px;
        }
        @media (min-width: 992px) {
            padding-top: 58px;
            padding-bottom: 58px;
        }
        @media (min-width: 1200px) {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        @media (min-width: 1600px) {
            padding-top: 92px;
            padding-bottom: 92px;
        }

        &-text {
            text-align: center;
            color: white;
            font-size: 20px;
            padding-bottom: 24px;

            @media (min-width: 576px) {
                font-size: 18px;
                padding-bottom: 26px;
            }
            @media (min-width: 768px) {
                font-size: 22px;
                padding-bottom: 32px;
            }
            @media (min-width: 992px) {
                font-size: 16px;
                padding-bottom: 24px;
            }
            @media (min-width: 1200px) {
                font-size: 20px;
                padding-bottom: 30px;
            }
            @media (min-width: 1600px) {
                font-size: 22px;
                padding-bottom: 24px;
            }
        }
    }

    &__time-to {
        border-radius: 4px;
        background: #f4f4f4;
        position: relative;
        z-index: 3;
        width: 300px;
        padding: 22px 10px;
        
        @media (min-width: 576px) {
            font-size: 18px;
            padding: 30px 12px;
            width: 412px;
        }
        @media (min-width: 768px) {
            width: 260px;
            font-size: 22px;
            padding: 22px 10px;
        }
        @media (min-width: 992px) {
            width: 320px;
            font-size: 16px;
            padding: 24px 10px;
        }
        @media (min-width: 1200px) {
            width: 400px;
            padding: 26px 12px;
        }
        @media (min-width: 1600px) {
            width: 458px;
            padding: 32px 12px;
        }
    }

    &__img-play {
        width: 24px;
        margin-right: 8px;

        @media (min-width: 768px) {
            width: 16px;
        }
        @media (min-width: 992px) {
            width: 20px;
        }
        @media (min-width: 1200px) {
            width: 22px;
        }
        @media (min-width: 1600px) {
            width: 28px;
        }
    }
}