.c-best-ally {
    width: 100%;
    background-color: #ffffff;
    padding-top: 3rem;
    padding-bottom: 5rem;

    @media (min-width: 576px) {
        padding-top: 4rem;
        padding-bottom: 6rem;
    }
    @media (min-width: 768px) {
        padding-top: 5rem;
        padding-bottom: 7rem;
    }
    @media (min-width: 992px) {
        padding-top: 6.5rem;
        padding-bottom: 6rem;
    }
    @media (min-width: 1200px) {
        padding-top: 7.25rem;
        padding-bottom: 10rem;
    }
    @media (min-width: 1600px) {
        padding-top: 8rem;
        padding-bottom: 12rem;
    }

    &__text {
        font-family: Majorant-Lt;
        font-size: 1.12rem;
        line-height: 1.25;
        padding-top: 0.5rem;

        @media (min-width: 576px) {
            font-size: 1.25rem;
            padding-top: 0.75rem;
        }
        @media (min-width: 768px) {
            font-size: 1.5rem;
            padding-top: 1rem;
        }
        @media (min-width: 992px) {
            font-size: 1.12rem;
            padding-top: 0rem;
        }
        @media (min-width: 1200px) {
            font-size: 1.25rem;
        }
        @media (min-width: 1600px) {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        &--title {
            font-family: Majorant-Bold;
            line-height: 1.25;
            font-size: 1.95rem;
            color: #000000;
    
            @media (min-width: 576px) {
                font-size: 2.5rem;
            }
            @media (min-width: 768px) {
                font-size: 3.5rem;
            }
            @media (min-width: 992px) {
                font-size: 4.75rem;
            }
            @media (min-width: 1200px) {
                font-size: 5.5rem;
            }
            @media (min-width: 1600px) {
                font-size: 7.5rem;
            }
        }
    }

    &__div {
        
        &--paragraph {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            @media (min-width: 576px) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
            @media (min-width: 768px) {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
            @media (min-width: 992px) {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            @media (min-width: 1200px) {
                padding-top: 6rem;
                padding-bottom: 6rem;
            }
            @media (min-width: 1600px) {
                padding-top: 8rem;
                padding-bottom: 8rem;
            }
        }
    }

    &__img {
        width: 100%;
        padding-bottom: 2.5rem;

        @media (min-width: 576px) {
            width: 95%;
            padding-bottom: 4rem;
        }
        @media (min-width: 768px) {
            width: 90%;
            padding-bottom: 5rem;
        }
        @media (min-width: 992px) {
            width: 660px;
            padding-bottom: 7rem;
        }
        @media (min-width: 1200px) {
            width: 780px;
            padding-bottom: 9.5rem;
        }
        @media (min-width: 1600px) {
            width: 1080px;
            padding-bottom: 12rem;
        }
    }

    &__btn {
        border: 2px solid #eb4d38;
        font-family: Majorant-Lt;
        color: #eb4d38;
        padding: 0.5rem 2rem;
        font-size: 1rem;
        text-decoration: none;

        &:hover {
            background-color: #eb4d38;
            transition-duration: 200ms;
            color: white;
        }

        @media (min-width: 576px) {
            padding: 0.75rem 2.25rem;
            font-size: 1.15rem;
        }
        @media (min-width: 768px) {
            padding: 1rem 2.75rem;
            font-size: 1.25rem;
        }
        @media (min-width: 992px) {
            padding: 1rem 3rem;
            font-size: 1.5rem;
        }
        @media (min-width: 1200px) {
            padding: 1.25rem 3.5rem;
            font-size: 2rem;
        }
        @media (min-width: 1600px) {
            padding: 1.5rem 5rem;
            font-size: 2.5rem;
        }
    }
}