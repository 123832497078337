.c-columns {
    &__five {
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 50%;

        @media (min-width: 768px) {
            flex: 0 0 20%;
            max-width: 20%;
            width: 20%;
        }

        &--full-width {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
}