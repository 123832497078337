.c-press {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 36px;

    @media (min-width: 768px) {
        padding-bottom: 160px;
    }

    &__container {
        padding-top: 42px;
        padding-bottom: 42px;

        @media (min-width: 768px) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
        @media (min-width: 992px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media (min-width: 1200px) {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        @media (min-width: 1600px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    &__box-info {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        z-index: 11;

        @media (min-width: 768px) {
            flex: 0 0 92%;
            width: 92%;
        }
    }

    &__image {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 0;
        padding-left: 0;

        &-token {
            width: 100%;
        }

        @media (min-width: 768px) {
            flex: 0 0 48%;
            width: 48%;
        }
    }

    &__description {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        background: #0b100f;
        padding: 40px 24px 4px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @media (min-width: 576px) {
            padding: 56px 40px 20px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        @media (min-width: 768px) {
            flex: 0 0 52%;
            width: 52%;
            border-top-left-radius: 0px;
            border-top-right-radius: 5px;
            padding: 20px 28px;
        }
        @media (min-width: 992px) {
            padding: 48px;

        }
        @media (min-width: 1200px) {
            padding: 60px 80px;
        }
        @media (min-width: 1600px) {
            padding: 90px;
        }

        &-title {
            color: white;
            font-size: 22px;
            line-height: 1.36;
            max-width: 272px;
            margin-right: auto;
            margin-left: auto;
            
            @media (min-width: 576px) {
                font-size: 39px;
                line-height: 1.3;
                max-width: unset;
                margin-right: unset;
                margin-left: unset;
            }
            @media (min-width: 768px) {
                font-size: 27px;
            }
            @media (min-width: 992px) {
                font-size: 31px;
            }
            @media (min-width: 1200px) {
                font-size: 35px;
                line-height: 1.5;
            }
            @media (min-width: 1600px) {
                font-size: 55px;
                line-height: 1.25;
            }
        }

        &-text {
            color: white;
            font-size: 15px;
            padding-top: 18px;
            line-height: 1.34;
            max-width: 272px;
            margin-right: auto;
            margin-left: auto;
            
            @media (min-width: 576px) {
                font-size: 25px;
                max-width: unset;
                margin-right: unset;
                margin-left: unset;
                padding-top: 30px;
            }
            @media (min-width: 768px) {
                font-size: 12px;
                padding-top: 16px;
                line-height: 1.3;
            }
            @media (min-width: 992px) {
                font-size: 16px;
                padding-top: 18px;
                line-height: 1.5;
            }
            @media (min-width: 1200px) {
                font-size: 16px;
                padding-top: 12px;
                line-height: 1.5;
            }
            @media (min-width: 1600px) {
                font-size: 22px;
                line-height: 1.5;
            }

            &--padding {
                padding-top: 26px;
                margin-bottom: 0px;
            
                @media (min-width: 576px) {
                    padding-top: 42px;
                }
                @media (min-width: 768px) {
                    padding-top: 28px;
                    position: absolute;
                    bottom: 20px;
                }
                @media (min-width: 992px) {
                    padding-top: 36px;
                }
                @media (min-width: 1200px) {
                    bottom: 25px;
                    padding-top: 40px;
                }
                @media (min-width: 1600px) {
                    bottom: 30px;
                    padding-top: 52px;
                }
            }
        }
    }

    &__bottom-line {
        display: none;
        height: 9px;
        background: #0b100f;
        width: 86.6%;
        margin-left: auto;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        @media (min-width: 768px) {
            display: block;
        }
    }

    &__arrows-pattern {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 10;
    }

    &__logo-forbes {
        height: 16px;
        vertical-align: top;
        margin-left: 12px;
        margin-right: 0px;

        @media (min-width: 576px) {
            height: 26px;
            margin-left: 16px;
        }
        @media (min-width: 768px) {
            height: 14px;
            margin-left: 12px;
        }
        @media (min-width: 992px) {
            height: 20px;
            margin-left: 12px;
        }
        @media (min-width: 1200px) {
            height: 20px;
            margin-left: 12px;
        }        
        @media (min-width: 1600px) {
            height: 26px;
            margin-left: 12px;
        }        
    }

    &__logo-fortuna {
        height: 26px;
        vertical-align: top;
        margin-left: 4px;
        margin-right: 0px;
        transform: translate(0px, -5px);

        @media (min-width: 576px) {
            height: 44px;
            margin-left: 4px;
            margin-right: 18px;
            transform: translate(0px, -8px);
        }
        @media (min-width: 768px) {
            height: 22px;
            margin-left: 3px;
            margin-right: 24px;
            transform: translate(0px, -4px);
        }
        @media (min-width: 992px) {
            height: 32px;
            margin-left: 4px;
            margin-right: 32px;
            transform: translate(0px, -6px);
        }
        @media (min-width: 1200px) {
            height: 32px;
        }
        @media (min-width: 1600px) {
            height: 44px;
            margin-right: 40px;
            transform: translate(0px, -8px);
        }     
    }

    &__logo-v2-forbes {
        width: 80%;
    }

    &__logo-v2-al-momento {
        width: 80%;
    }

    &__logo-v2-fortuna-bg {
        width: 80%;
    }
}