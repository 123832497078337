// SETTINGS
/* ========================================================================================= */
/* SETTINGS */
/* ========================================================================================= */
@import "scss/settings/_settings";
/* ========================================================================================= */
/* END SETTINGS */
/* ========================================================================================= */

section, div, header, footer {
    z-index: 50;
    position: relative;
}

#__convert-currency {
    z-index: 0;
}

@font-face {
    font-family: Majorant-Bold;
    src: url(./assets/fonts/majorant/Majorant-Bold.otf);
}

@font-face {
    font-family: Majorant-Blk;
    src: url(./assets/fonts/majorant/Majorant-Blk.ttf);
}

@font-face {
    font-family: Majorant-Medium;
    src: url(./assets/fonts/majorant/Majorant-Medium.otf);
}

@font-face {
    font-family: Majorant-Regular;
    src: url(./assets/fonts/majorant/Majorant-Regular.otf);
}

@font-face {
    font-family: Majorant-Lt;
    src: url(./assets/fonts/majorant/Majorant-Lt.ttf);
}

@font-face {
    font-family: Majorant-Th;
    src: url(./assets/fonts/majorant/Majorant-Th.ttf);
}

@font-face {
    font-family: Majorant-UlTh;
    src: url(./assets/fonts/majorant/Majorant-UlTh.ttf);
}

footer {
    background: #034066;
}

/**
 *  Generales
 */ 
.txt-10{
    font-size: 10px;
}

body {
    background: #003936;
    font-family: Majorant-Lt;
}

button {
    border: none;
    display: inline-block;
    color: white;
    position: relative;
    z-index: 1;
    font-size: 16px;
    padding: 5px 15px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    right: 0;
}

.home-section {
    overflow: hidden;
    position: relative;
}

.text__not-supported {
    color: white;
    font-size: 24px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
}

.not-supported {
    display: none;
    height: 100vh;
    width: 100vw;
}

.vertical-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.vertical-center-low {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-center-academy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    @media (min-width: 576px) {
        margin-bottom: 80px;
    }
    @media (min-width: 768px) {
        justify-content: end;
        margin-bottom: 0px;
    }
}

.PriceXoycoinQuery{
    @media (min-width: 768px) {
        display: none;
    }
}

// @media (max-width: 1199px) {
//     .home-section {
//         display: none;
//     }
//     .not-supported {
//         min-height: 10em;
//         display: table-cell;
//         vertical-align: middle;
//         justify-content: center;
//         align-items: center;
//     }
// }



// COMPONENTS
/* ========================================================================================= */
/* COMPONENTS */
/* ========================================================================================= */
@import "scss/components/_c-best-ally";
@import "scss/components/_c-buttons";
@import "scss/components/_c-calculator";
@import "scss/components/_c-challenging";
@import "scss/components/_c-columns";
@import "scss/components/_c-convert-currency";
@import "scss/components/_c-documents";
@import "scss/components/_c-footer";
@import "scss/components/_c-get-ready";
@import "scss/components/_c-header";
@import "scss/components/_c-last-div";
@import "scss/components/_c-menu-dropdown";
@import "scss/components/_c-modals";
@import "scss/components/_c-our-community";
@import "scss/components/_c-owl-carrousel";
@import "scss/components/_c-press";
@import "scss/components/_c-project";
@import "scss/components/_c-register";
@import "scss/components/_c-roadmap";
@import "scss/components/_c-steps";
@import "scss/components/_c-team";
@import "scss/components/_c-token";
@import "scss/components/_c-tutorial-token";
@import "scss/components/_c-videos";

.c-whatsapp {
    &__position {
        position: fixed;
        z-index: 9999;
        bottom: 50px;
        transform: translate(-50%, -50%);
        right: 0px;
    
        @media (min-width: 576px) {
            bottom: 56px;
            right: 3px;
        }
        @media (min-width: 768px) {
            bottom: 62px;
            right: 6px;
        }
        @media (min-width: 992px) {
            bottom: 68px;
            right: 9px;
        }
        @media (min-width: 1200px) {
            bottom: 72px;
            right: 12px;
        }
        @media (min-width: 1600px) {
            bottom: 80px;
            right: 15px;
        }
    }

    &__link {
        position: absolute;
        right: 0;
        background: linear-gradient(45deg, #00FD66, #00B466);
        border-radius: 5px;
        padding: 5px 10px;
        line-height: 1;
    
        @media (min-width: 576px) {
            border-radius: 6px;
            padding: 6px 12px;
        }
        @media (min-width: 768px) {
            border-radius: 7px;
            padding: 7px 14px;
        }
        @media (min-width: 992px) {
            border-radius: 8px;
            padding: 8px 16px;
        }
        @media (min-width: 1200px) {
            border-radius: 9px;
            padding: 9px 18x;
        }
        @media (min-width: 1600px) {
            border-radius: 10px;
            padding: 10px 20px;
        }
    }

    &__icon {
        width: 20px;
    
        @media (min-width: 576px) {
            width: 24px;
        }
        @media (min-width: 768px) {
            width: 28px;
        }
        @media (min-width: 992px) {
            width: 32px;
        }
        @media (min-width: 1200px) {
            width: 36px;
        }
        @media (min-width: 1600px) {
            width: 40px;
        }
    }
}


.c-steps2 {
    &__container {
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 576px) {
            padding-top: 44px;
            padding-bottom: 44px;
            max-width: unset;
        }
        @media (min-width: 768px) {
            padding-top: 48px;
            padding-bottom: 48px;
        }
        @media (min-width: 992px) {
            padding-top: 52px;
            padding-bottom: 52px;
        }
        @media (min-width: 1200px) {
            padding-top: 56px;
            padding-bottom: 56px;
        }
        @media (min-width: 1200px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    &__text {
        color: white;
        margin-bottom: 0px;
        line-height: 1.5;

        @media (min-width: 576px) {
            line-height: 1.8;
        }
        @media (min-width: 768px) {
            line-height: 2;
        }
        @media (min-width: 992px) {
            line-height: 2;
        }
        @media (min-width: 1200px) {
            line-height: 2.5;
        }
    }

    &__min-height {
        min-height: 110px;
        width: 240px;
        margin-left: auto;
        margin-right: auto;
        
        @media (min-width: 576px) {
            min-height: 270px;
        }
        @media (min-width: 768px) {
            min-height: unset;
            width: unset;
        }
    }

    &__img {
        position: absolute;
        top: -50%;
        left: 70%;
        width: 100%;
        z-index: 2;
        transform: rotate(-65deg) translate(-30%, -75%);

        @media (min-width: 576px) {
            width: 700px;
            left: 262px;
            top: -140px;
        }
        @media (min-width: 768px) {
            width: 700px;
            left: -256px;
            transform: rotate(0deg);
            top: -120px;
        }
        @media (min-width: 992px) {
            width: 670px;
            top: -80px;
            left: -200px;
        }
        @media (min-width: 1200px) {
            width: 880px;
            top: -150px;
            left: -290px;
        }
        @media (min-width: 1600px) {
            width: 940px;
            top: -160px;
            left: -100px;
        }
    }

    &__img-green {
        position: absolute;
        top: -62%;
        left: 70%;
        width: 100%;
        z-index: 1;
        transform: rotate(-65deg) translate(-30%, -66%);

        @media (min-width: 576px) {
            width: 460px;
            top: -80px;
            left: 90%;
        }
        @media (min-width: 768px) {
            width: 500px;
            left: -190px;
            transform: rotate(-0deg) translate(0%, 0%);
        }
        @media (min-width: 992px) {
            width: 540px;
            top: -80px;
            left: -150px;
        }
        @media (min-width: 1200px) {
            width: 640px;
            top: -120px;
            left: -200px;
        }
        @media (min-width: 1600px) {
            width: 630px;
            top: -130px;
            left: 0px;
        }
    }

    &__li {
        list-style-type: decimal;
        color: white;
        font-size: 12px;

        @media (min-width: 576px) {
            font-size: 16px;
        }
        @media (min-width: 768px) {
            font-size: 14px;
        }
        @media (min-width: 992px) {
            font-size: 18px;
        }
        @media (min-width: 1200px) {
            font-size: 19px;
        }
        @media (min-width: 1600px) {
            font-size: 20px;
        }

        & a {
            text-decoration: underline;
            color: white;
        }

        &::marker {
            font-size: 14px;
    
            @media (min-width: 576px) {
                font-size: 16px;
            }
            @media (min-width: 768px) {
                font-size: 14px;
            }
            @media (min-width: 992px) {
                font-size: 18px;
            }
            @media (min-width: 1200px) {
                font-size: 19px;
            }
            @media (min-width: 1600px) {
                font-size: 20px;
            }
        }
    }
}

/* ======================================================================================== */
/* MENU DROPDOWN */
/* ======================================================================================== */

.c-buttons__menu-dropdown-border{
    &.active{
        color: white;
        background: linear-gradient(45deg, #00fd66, #00b466) !important;
    }
}

.c-buttons__menu-dropdown-plane{
    &.l{
        width: 210px;
        padding: 9px 10px 12px;
    }
}

.c-menu-dropdown__container{
    &__submenu{
        height: auto;
    }
}



/* ========================================================================================= */
/* END COMPONENTS */
/* ========================================================================================= */

// OBJECTS
/* ========================================================================================= */
/* OBJECTS */
/* ========================================================================================= */
@import "scss/objects/_o-aling-text";
@import "scss/objects/_o-column-direction";
@import "scss/objects/_o-container";
@import "scss/objects/_o-font";
/* ========================================================================================= */
/* END OBJECTS */
/* ========================================================================================= */