.c-footer {
    width: 100%;
    background-color: #001917;
    padding-top: 1.25rem;
    padding-bottom: 4rem;

    @media (min-width: 576px) {
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
    @media (min-width: 768px) {
        padding-top: 1.25rem;
        padding-bottom: 4rem;
    }
    @media (min-width: 992px) {
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
    @media (min-width: 1200px) {
        padding-top: 1.25rem;
        padding-bottom: 4rem;
    }
    @media (min-width: 1600px) {
        padding-top: 1.5rem;
        padding-bottom: 5rem;
    }
    
    &__social-div {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
            
        @media (min-width: 576px) {
            margin-top: 1.15rem;
            margin-bottom: 1.1rem;
            margin-right: 1rem;
        }
        @media (min-width: 768px) {
            margin-top: 1.15rem;
            margin-bottom: 1.1rem;
            margin-right: 1rem;
        }
        @media (min-width: 992px) {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
            margin-right: 0.75rem;
        }
        @media (min-width: 1200px) {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            margin-right: 1.25rem;
        }
        @media (min-width: 1600px) {
            margin-top: 2.1rem;
            margin-bottom: 2.1rem;
            margin-right: 1.75rem;
        }
    }

    &__logo {
        width: 4rem;
        margin-top: 0rem;
        margin-bottom: 1rem;

        &:hover {
            filter: drop-shadow(0px 0px 2px #03FB66);
            transition: all 300ms ease;
        }

        @media (min-width: 576px) {
            width: 2.25rem;
            margin-top: 0.5rem;
            margin-bottom: 1.4rem;
        }
        @media (min-width: 768px) {
            width: 2rem;
            margin-top: 0.2rem;
            margin-bottom: 1.65rem;
        }
        @media (min-width: 992px) {
            width: 2.35rem;
            margin-top: 0.1rem;
            margin-bottom: 1rem;
        }
        @media (min-width: 1200px) {
            width: 3rem;
            margin-top: 0.15rem;
            margin-bottom: 1rem;
        }
        @media (min-width: 1600px) {
            width: 3.65rem;
            margin-top: 0.2rem;
            margin-bottom: 1.75rem;
        }

        &--social-media {
            margin: 0;
            width: 3.25rem;
            
            @media (min-width: 576px) {
                width: 3rem;
            }
            @media (min-width: 768px) {
                width: 2.75rem;
            }
            @media (min-width: 992px) {
                width: 2.25rem;
            }
            @media (min-width: 1200px) {
                width: 2.75rem;
            }
            @media (min-width: 1600px) {
                width: 3.5rem;
            }
        }
    }

    &__menu {
        margin-bottom: 0;
        padding: 0;
        list-style: none;
    }

    &__menu-item {
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;

        @media (min-width: 576px) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        @media (min-width: 768px) {
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }
        @media (min-width: 992px) {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
        @media (min-width: 1200px) {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        @media (min-width: 1600px) {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
    }

    &__menu-item-link {
        text-decoration: none;
        font-family: Majorant-Th;

        &:hover {
            color: #eb4d38;
            cursor: pointer;
            transition: all 300ms ease;
        }
    }

    &__text {
        color: #ffffff;
        font-family: Majorant-Th;
        margin-top: 0rem;
        margin-bottom: 0rem;
        font-size: 0.95rem;

        @media (min-width: 576px) {
            font-size: 0.85rem;
        }
        @media (min-width: 768px) {
            font-size: 0.9rem;
        }
        @media (min-width: 992px) {
            font-size: 0.75rem;
        }
        @media (min-width: 1200px) {
            font-size: 0.9rem;
        }
        @media (min-width: 1600px) {
            font-size: 1.25rem;
        }

        &--nav-title {
            font-family: Majorant-Medium;
        }

        &--nav-legend {
            font-family: Majorant-Lt;
        }

        &--alert-ok {
            font-family: Majorant-Lt;
            color: #03fb66;
            margin: 0;
            line-height: 1.25;
        }

        &--alert-error {
            font-family: Majorant-Lt;
            color: #eb4d38;
            margin: 0;
            line-height: 1.25;
        }
    }

    &__form {
        z-index: 10;
    }

    &__form-suscription {
        display: flex;
    }
    
    &__form-control {
        background: #000000;
        border: 2px solid #03fb66;
        color: white;
        border-radius: 15px;
        margin-right: 3px;
        width: 100%;
        text-align: center;
        padding: 4px 16px 6px;
        width: calc(100% - 36px);
        margin-right: 2px;
        margin-top: 12px;
        margin-bottom: 12px;

        &::placeholder {
            color: #03fb66;
        }

        &:focus {
            border-color: #03fb66;
            box-shadow: 0 0 0 0.25rem rgb(3 251 102 / 15%);
            background: #000000;
            color: #03fb66;
        }
        
        @media (min-width: 576px) {
            padding: 4px 16px 8px;
            width: calc(100% - 38px);
            margin-right: 2px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        @media (min-width: 768px) {
            padding: 4px 12px 6px;
            width: calc(100% - 51px);
            margin-right: 2px;
            margin-top: 6px;
            margin-bottom: 6px;
        }
        @media (min-width: 992px) {
            padding: 6px 12px 6px;
            width: calc(100% - 51px);
            margin-right: 2px;
            margin-top: 6px;
            margin-bottom: 5px;
        }
        @media (min-width: 1200px) {
            padding: 8px 16px 10px;
            width: calc(100% - 62px);
            margin-right: 3px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        @media (min-width: 1600px) {
            padding: 8px 16px 10px;
            width: calc(100% - 71px);
            margin-right: 4px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    &__form-img-send {
        width: 24px;
        margin-bottom: 4px;
        display: inline;
        
        @media (min-width: 576px) {
            width: 18px;
            margin-bottom: 4px;
        }
        @media (min-width: 768px) {
            width: 14px;
            margin-bottom: 5px;
        }
        @media (min-width: 992px) {
            width: 18px;
            margin-bottom: 4px;
        }
        @media (min-width: 1200px) {
            width: 22px;
            margin-bottom: 0px;
        }
        @media (min-width: 1600px) {
            width: 26px;
            margin-bottom: 0px;
        }
    }

    &__div-links {
        order: 2;

        @media (min-width: 992px) {
            order: 1;
        }
    }
    &__div-social-form {
        order: 1;
        padding-bottom: 1.75rem;
        
        @media (min-width: 992px) {
            order: 2;
            padding-bottom: 0;
        }
    }
}