.c-steps {
    overflow: hidden;
    position: relative;
    background-color: #003936;
    z-index: 100;
    padding-top: 2.5rem;
    padding-bottom: 3rem;

    @media (min-width: 576px) {
        padding-top: 3rem;
        padding-bottom: 6rem;
    }
    @media (min-width: 768px) {
        padding-top: 3.5rem;
        padding-bottom: 8rem;
    }
    @media (min-width: 992px) {
        padding-top: 3rem;
        padding-bottom: 9rem;
    }
    @media (min-width: 1200px) {
        padding-top: 3.5rem;
        padding-bottom: 10.5rem;
    }
    @media (min-width: 1600px) {
        padding-top: 4rem;
        padding-bottom: 12rem;
    }

    &_container {}

    &__li-pb {
        @media (min-width: 576px) {
        }
        @media (min-width: 768px) {
            padding-bottom: 1.5rem;
        }
        @media (min-width: 992px) {
            padding-bottom: 1.75rem;
        }
        @media (min-width: 1200px) {
            padding-bottom: 2.25rem;
        }
        @media (min-width: 1600px) {
            padding-bottom: 3.5rem;
        }

        &:last-child {
            padding-bottom: 0rem;
        }
    }

    &__li::marker {
        color: #FF6B17;
    }

    &__text {
        color: #ffffff;
        font-family: Majorant-Regular;
        line-height: 1.25;
        font-size: 1rem;
        margin-bottom: .5rem;

        &--title {
            font-family: Majorant-Blk;
            font-size: 1rem;
            text-align: center;
            font-size: 2.25rem;
            padding-bottom: 1.5rem;

            @media (min-width: 576px) {
                font-size: 3.25rem;
                padding-bottom: 2.25rem;
            }
            @media (min-width: 768px) {
                font-size: 3.5rem;
                padding-bottom: 2.5rem;
            }
            @media (min-width: 992px) {
                font-size: 4rem;
                padding-bottom: 6rem;
            }
            @media (min-width: 1200px) {
                font-size: 5rem;
                padding-bottom: 8.5rem;
            }
            @media (min-width: 1600px) {
                font-size: 6rem;
                padding-bottom: 10rem;
            }
        }

        &--point {
            font-family: Majorant-Lt;

            & span {
                font-family: Majorant-Bold;
            }

            & a {
                color: white;

                &:hover {
                    color: #eb4d38;
                    cursor: pointer;
                    transition: all 300ms ease;
                }
            }

            @media (min-width: 576px) {
                font-size: 1.25rem;
            }
            @media (min-width: 768px) {
                font-size: 1.75rem;
            }
            @media (min-width: 992px) {
                font-size: 1.1rem;
            }
            @media (min-width: 1200px) {
                font-size: 1.25rem;
            }
            @media (min-width: 1600px) {
                font-size: 1.85rem;
            }
        }

        &--ok {
            font-family: Majorant-Lt;
            
            @media (min-width: 576px) {
                font-size: 1.25rem;
            }
            @media (min-width: 768px) {
                font-size: 1.75rem;
            }
            @media (min-width: 992px) {
                font-size: 1.6rem;
            }
            @media (min-width: 1200px) {
                font-size: 1.75rem;
            }
            @media (min-width: 1600px) {
                font-size: 2.25rem;
            }
        }
    }

    &__text-area {
        background: #00000000;
        border: 0;
        width: 100%;
        color: #000000;
        text-align: center;
        font-size: 0.65rem;
            
        @media (min-width: 576px) {
            font-size: 0.8rem;
        }
        @media (min-width: 768px) {
            font-size: 0.95rem;
        }
        @media (min-width: 992px) {
            font-size: 0.9rem;
        }
        @media (min-width: 1200px) {
            font-size: 1.75rem;
            font-size: 1rem;
        }
        @media (min-width: 1600px) {
            font-size: 1.35rem;
        }
    }

    &__img {
        width: 100%;

        @media (max-width: 575px) {
            max-width: 200px;
            padding-top: 2rem;
        }
        @media (max-width: 767px) {
            max-width: 280px;
            padding-top: 2.5rem;
        }
        @media (max-width: 991px) {
            max-width: 360px;
            padding-top: 3rem;
        }

        &--ok {
            width: 55px;
            max-width: unset;
            padding-top: 0;

            @media (min-width: 576px) {
                width: 60px;
            }
            @media (min-width: 768px) {
                width: 70px;
            }
            @media (min-width: 992px) {
                width: 80px;
            }
            @media (min-width: 1200px) {
                width: 90px;
            }
            @media (min-width: 1600px) {
                width: 100px;
            }
        }
    }

    &__div {
        display: flex;
        justify-content: center;
        align-items: end;

        &--ok-image {
            padding-top: 3rem;
            padding-bottom: 1.5rem;

            @media (min-width: 576px) {
                padding-top: 4rem;
                padding-bottom: 2rem;
            }
            @media (min-width: 768px) {
                padding-top: 5rem;
                padding-bottom: 2.5rem;
            }
            @media (min-width: 992px) {
                padding-top: 6rem;
                padding-bottom: 3rem;
            }
            @media (min-width: 1200px) {
                padding-top: 8rem;
                padding-bottom: 3.5rem;
            }
            @media (min-width: 1600px) {
                padding-top: 10rem;
                padding-bottom: 4rem;
            }
        }
    }
}