.c-tutorial-token {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #034066;

    &__container {
        padding-top: 3rem;
        padding-bottom: 5rem;
    
        @media (min-width: 576px) {
            padding-top: 5rem;
            padding-bottom: 7rem;
        }
        @media (min-width: 768px) {
            padding-top: 6rem;
            padding-bottom: 8rem;
        }
        @media (min-width: 992px) {
            padding-top: 4rem;
            padding-bottom: 6rem;
        }
        @media (min-width: 1200px) {
            padding-top: 6rem;
            padding-bottom: 8rem;
        }
        @media (min-width: 1600px) {
            padding-top: 8rem;
            padding-bottom: 10rem;
        }
    }

    &__text {
        color: #ffffff;
        font-family: Majorant-Lt;
        line-height: 1.25;
        font-size: 1.12rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center;

        @media (min-width: 576px) {
            font-size: 1.25rem;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
        @media (min-width: 768px) {
            font-size: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        @media (min-width: 992px) {
            text-align: left;
            font-size: 1.06rem;
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
        @media (min-width: 1200px) {
            font-size: 1.2rem;
        }
        @media (min-width: 1600px) {
            font-size: 1.6rem;
        }

        &--l1 {
            margin-left: 0%;

            @media (min-width: 992px) {
                margin-left: 10%;
            }
        }

        &--title {
            font-family: Majorant-Bold;
            font-size: 3.6rem;
            padding-top: 0rem;
            padding-bottom: 2rem;

            @media (min-width: 576px) {
                font-size: 4.25rem;
                padding-bottom: 2.25rem;
            }
            @media (min-width: 768px) {
                font-size: 6rem;
                padding-bottom: 2.5rem;
            }
            @media (min-width: 992px) {
                font-size: 3.75rem;
                padding-bottom: 3rem;
            }
            @media (min-width: 1200px) {
                font-size: 4.25rem;
                padding-bottom: 4rem;
            }
            @media (min-width: 1600px) {
                font-size: 6rem;
                padding-bottom: 5rem;
            }
        }

        &--subtitle {
            font-family: Majorant-Bold;
            color: #03FB66;
            padding-top: 0rem;
            padding-bottom: 0.75rem;
            font-size: 1.35rem;

            @media (min-width: 576px) {
                padding-bottom: 1rem;
                font-size: 1.5rem;
            }
            @media (min-width: 768px) {
                padding-bottom: 1.25rem;
                font-size: 2rem;
            }
            @media (min-width: 992px) {
                padding-bottom: 0.75rem;
                font-size: 1.35rem;
            }
            @media (min-width: 1200px) {
                padding-bottom: 1rem;
                font-size: 1.5rem;
            }
            @media (min-width: 1600px) {
                padding-bottom: 1.25rem;
                font-size: 2rem;
            }
        }

        &--subtitle-tall {
            font-family: Majorant-Bold;
            color: #03FB66;
            font-size: 2rem;
            padding-top: 0rem;

            @media (min-width: 576px) {
                font-size: 2.25rem;
            }
            @media (min-width: 768px) {
                font-size: 2.5rem;
            }
            @media (min-width: 992px) {
                font-size: 2rem;
            }
            @media (min-width: 1200px) {
                font-size: 2.25rem;
            }
            @media (min-width: 1600px) {
                font-size: 2.5rem;
            }
        }

        &--space-left {
            padding-left: 0rem;

            @media (min-width: 576px) {
            }
            @media (min-width: 768px) {
            }
            @media (min-width: 992px) {
                padding-left: 2rem;
            }
            @media (min-width: 1200px) {
                padding-left: 2.25rem;
            }
            @media (min-width: 1600px) {
                padding-left: 4.25rem;
            }
        }
    }

    &__block {
        height: 400px;
    }

    &__grid {
        grid-template-columns: repeat(1,minmax(0,1fr));
        display: grid;
        max-width: 270px;
        margin: 0 auto;

        @media (min-width: 576px) {
            max-width: unset;
            margin: 0 80px;
        }
        @media (min-width: 768px) {
            margin: 0 120px;
        }
        @media (min-width: 992px) {
            grid-template-columns: repeat(2,minmax(0,1fr));
            padding-top: 2rem;
            margin: 0 80px;
        }
        @media (min-width: 1200px) {
            margin: 0 120px;
        }
        @media (min-width: 1600px) {
            margin: 0 160px;
        }
    }

    &__block {
        height: 200px;
        
        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &--first {
            height: auto;
        }

        &--second {
            height: auto;
            
            @media (min-width: 992px) {
                height: 180px;
            }
            @media (min-width: 1200px) {
                height: 280px;
            }
            @media (min-width: 1600px) {
                height: 380px;
            }
        }

        &--third {
            height: auto;
            
            @media (min-width: 992px) {
                height: 510px;
            }
            @media (min-width: 1200px) {
                height: 580px;
            }
            @media (min-width: 1600px) {
                height: 800px;
            }
        }

        &--fourth {
            height: auto;
            
            @media (min-width: 992px) {
                height: 320px;
            }
            @media (min-width: 1200px) {
                height: 380px;
            }
            @media (min-width: 1600px) {
                height: 480px;
            }
        }

        &--fifth {
            height: auto;
        }
    }

    &__copy-content {
        @media (min-width: 768px) {
            padding-top: .5rem;
            padding-bottom: .75rem;
        }
        @media (min-width: 992px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        @media (min-width: 1200px) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        @media (min-width: 1600px) {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }

    &__image {
        width: 80%;
        margin-bottom: 1rem;

        @media (min-width: 576px) {
            margin-bottom: 2rem;
        }
        @media (min-width: 768px) {
            margin-bottom: 3rem;
        }
        @media (min-width: 992px) {
            width: 60%;        
            margin-bottom: 0rem;
        }

        &--full {
            width: 100%;
        }

        &--r1 {
            margin-right: 0%;

            @media (min-width: 992px) {
                margin-right: 10%;
            }
        }

        &--l1 {
            margin-left: 0%;

            @media (min-width: 992px) {
                margin-left: 10%;
            }
        }

        &--l15 {
            margin-left: 0%;

            @media (min-width: 992px) {
                margin-left: 15%;
            }
        }

        &--p-tb-box {
            padding-top: 0;
            padding-bottom: 0;
            
            @media (min-width: 992px) {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }
            @media (min-width: 1200px) {
                padding-top: 2.25rem;
                padding-bottom: 2.25rem;
            }
            @media (min-width: 1600px) {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }

        &--mar-top-40 {
            margin-top: 0%;

            @media (min-width: 992px) {
                margin-top: 40%;
            }
        }

        &--unicorn-steps {
            width: 90%;
            top: 0%;
            position: relative;
            
            @media (min-width: 992px) {
                top: 25%;
                left: 8%;
            }
            @media (min-width: 1200px) {
                top: 25%;
                left: 12%;
            }
            @media (min-width: 1600px) {
                left: 10%;
            }
        }

        &--donia {
            width: 100%;
            top: 0%;
            position: relative;
            
            @media (min-width: 992px) {
                top: -40%;
                right: 10%;
            }
            @media (min-width: 1200px) {
                top: -35%;
                right: 10%;
            }
            @media (min-width: 1600px) {
                top: -45%;
                right: 10%;
            }
        }

        &--last-box {
            top: 0%;
            position: relative;
            
            @media (min-width: 992px) {
                top: -30%;
            }
            @media (min-width: 1200px) {
                top: -35%;
            }
            @media (min-width: 1600px) {
                top: -30%;
            }

        }
    }

    &__button-adress {
        background-color: white;
        border-radius: 9999px;
        font-family: Majorant-Regular;
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        font-size: .66rem;
        padding: 4px 4px 4px 4px;

        @media (min-width: 576px) {
            margin-top: 1.75rem;
            margin-bottom: 1.75rem;
            font-size: .80rem;
        }
        @media (min-width: 768px) {
            margin-top: 2.25rem;
            margin-bottom: 2.25rem;
            font-size: .95rem;
        }
        @media (min-width: 992px) {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            font-size: .85rem;
        }
        @media (min-width: 1200px) {
            margin-top: 2.25rem;
            margin-bottom: 2.25rem;
            font-size: .95rem;
        }
        @media (min-width: 1600px) {
            margin-top: 3rem;
            margin-bottom: 3rem;
            font-size: 1.25rem;
        }
    }

    &__first-step {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin-bottom: 0rem;

        @media (min-width: 576px) {
            margin-bottom: 1rem;
        }
        @media (min-width: 768px) {
            margin-bottom: 2rem;
        }
        @media (min-width: 992px) {
            margin-bottom: 2rem;
        }
        @media (min-width: 1200px) {
            margin-bottom: 3rem;
        }
        @media (min-width: 1600px) {
            margin-bottom: 4rem;
        }
    }

}