.c-roadmap {
    width: 100%;
    padding-top: 52px;
    padding-bottom: 0px;

    &__container {
    }

    &__text {
        color: white;
        text-align: center;

        &--detail {
            margin: 22px auto 0;

            @media (min-width: 576px) {
                margin: 20px auto 0;
            }
            @media (min-width: 768px) {
                max-width: 570px;
                margin: 18px auto 0;
            }
            @media (min-width: 992px) {
                max-width: 730px;
                margin: 20px auto 0;
            }
            @media (min-width: 1200px) {
                max-width: 810px;
                margin: 22px auto 0;
            }
            @media (min-width: 1600px) {
                max-width: 900px;
                margin: 48px auto 0;
            }
        }

        &--subtitle {
            color: #5AFF7A;
            font-size: 19px;
            
            @media (min-width: 576px) {
                font-size: 22px;
            }
            @media (min-width: 768px) {
                font-size: 25px;
            }
            @media (min-width: 992px) {
                font-size: 22px;
            }
            @media (min-width: 1200px) {
                font-size: 25px;
            }
            @media (min-width: 1600px) {
                font-size: 28px;
            }
        }
    }
}