.c-documents {
    width: 100%;
    padding-top: 54px;
    padding-bottom: 54px;

    @media (min-width: 576px) {
        padding-top: 76px;
        padding-bottom: 76px;
    }
    @media (min-width: 768px) {
        padding-top: 98px;
        padding-bottom: 98px;
    }
    @media (min-width: 992px) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: 1200px) {
        padding-top: 142px;
        padding-bottom: 142px;
    }
    @media (min-width: 1600px) {
        padding-top: 164px;
        padding-bottom: 164px;
    }

    &__container {
    }

    &__text {
        color: white;
        text-align: center;
        font-size: 13px;

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 15px;
        }
        @media (min-width: 992px) {
            font-size: 14px;
        }
        @media (min-width: 1200px) {
            font-size: 15px;
        }
        @media (min-width: 1600px) {
            font-size: 16px;
        }

        &--subtitle {
            color: #5AFF7A;
            font-size: 19px;
            
            @media (min-width: 576px) {
                font-size: 22px;
            }
            @media (min-width: 768px) {
                font-size: 25px;
            }
            @media (min-width: 992px) {
                font-size: 22px;
            }
            @media (min-width: 1200px) {
                font-size: 25px;
            }
            @media (min-width: 1600px) {
                font-size: 28px;
            }
        }
    }
}