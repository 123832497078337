.c-menu-dropdown {
    transition: all 1.2s cubic-bezier(.19,.77,.22,1);
    transform: translateY( -101% );
    z-index: 9998;
    position: fixed;
    right: 0;
    top: 0px;
    height: 100vh;
    width: 100%;
    display: none;
    margin: 0 auto;
    background-color: #EEEEEE;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &--top {
        z-index: 9999;
    }

    &.is-active{
        transform: translateY( 0% );
        display: block;
    }

    &__header {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 10px;
        margin-bottom: 10px;

        @media (min-width: 576px) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    &__logo {
        height: 22px;

        @media (min-width: 576px) {
            height: 26px;
        }
    }

    &__close {
        color: #003936;
        text-align: right;
    }

    &__container {
        height: calc(100vh - 44px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__nav {
        padding-top: 16px;
        padding-right: 15px;
        padding-bottom: 16px;
        padding-left: 15px;
        
        @media (min-width: 576px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &__menu {
        list-style: none;
        padding: 0;
    }

    &__menu-item {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        
        @media (min-width: 576px) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    &__menu-item-link {
        color: #003936;
        padding-left: 4px;
        padding-right: 4px;
        position: relative;
        text-decoration: none;
        font-size: 1.5rem;

        &:after {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            content: "";
            display: block;
            width: 0px;
            height: 3px;
            transition: all 300ms ease;
            text-align: center;
            opacity: 1;
            background: rgba(0,253,101,1);
            background: -moz-linear-gradient(left, rgba(0,253,101,1) 0%, rgba(0,180,102,1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,253,101,1)), color-stop(100%, rgba(0,180,102,1)));
            background: -webkit-linear-gradient(left, rgba(0,253,101,1) 0%, rgba(0,180,102,1) 100%);
            background: -o-linear-gradient(left, rgba(0,253,101,1) 0%, rgba(0,180,102,1) 100%);
            background: -ms-linear-gradient(left, rgba(0,253,101,1) 0%, rgba(0,180,102,1) 100%);
            background: linear-gradient(to right, rgba(0,253,101,1) 0%, rgba(0,180,102,1) 100%);
        }

        &.active:after {
            width: 100%;
        }

        &:active {
            color: #eb4d38;
        }

        @media (min-width: 576px) {
            font-size: 1.75rem;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}