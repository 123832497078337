.o-column-direction {
    flex-direction: column-reverse;
    text-align: left;

    &--text-center-cellphone {
        text-align: center;
        
        @media (min-width: 768px) {
            text-align: left;
        }        
    }

    @media (min-width: 768px) {
        flex-direction: row;
        text-align: left;
    }
}