.o-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    margin: 0 auto;
    position: relative;

    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1600px) {
        max-width: 1570px;
    }

    &__major-z-index {
        position: relative;
        z-index: 10;
    }

    &--telegram {
        max-width: 100%;

        @media (min-width: 768px) {
            max-width: 850px;
        }
        @media (min-width: 992px) {
            max-width: 1100px;
        }
        @media (min-width: 1200px) {
            max-width: 1300px;
        }
        @media (min-width: 1600px) {
            max-width: 1700px;
        }
    }
}