.c-our-community {
    background: #03fb66;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media (min-width: 576px) {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    @media (min-width: 768px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    @media (min-width: 992px) {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }
    @media (min-width: 1200px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    @media (min-width: 1600px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    &__img {
        width: 100%;
        padding-top: 0.5rem;

        @media (min-width: 576px) {
            padding-top: 0.75rem;
        }
        @media (min-width: 768px) {
            padding-top: 1rem;
        }
        @media (min-width: 992px) {
            padding-top: 0.5rem;
        }
        @media (min-width: 1200px) {
            padding-top: 0.75rem;
        }
        @media (min-width: 1600px) {
            padding-top: 1rem;
        }
    }

    &__item {
        display: block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @media (min-width: 576px) {
            margin-left: 0.85rem;
            margin-right: 0.85rem;
        }
        @media (min-width: 768px) {
            margin-left: 0.95rem;
            margin-right: 0.95rem;
        }
        @media (min-width: 992px) {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
        }
        @media (min-width: 1200px) {
            margin-left: 0.85rem;
            margin-right: 0.85rem;
        }
        @media (min-width: 1600px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    &__text {
        &--title {
            text-transform: uppercase;
            font-family: Majorant-Medium;
            text-align: center;
            color: #003936;
            font-size: 1rem;
            margin-bottom: 0.5rem;

            @media (min-width: 576px) {
                font-size: 1rem;
                margin-bottom: 0.75rem;
            }
            @media (min-width: 768px) {
                margin-bottom: 1rem;
            }
            @media (min-width: 992px) {
                font-size: 0.75rem;
                margin-bottom: 0.75rem;
            }
            @media (min-width: 1200px) {
                font-size: 0.85rem;
            }
            @media (min-width: 1600px) {
                font-size: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}