.c-header {
    position: sticky;
    z-index: 999;
    width: 100%;
    top: 0;
    padding-bottom: 0;
    background: rgba(0, 57, 54, 0.75);

    &__cont-solid {
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
        padding-bottom: 6px;
        z-index: 20;
        
        @media (min-width: 576px) {
            padding-top: 7px;
            padding-bottom: 7px;
        }
        @media (min-width: 768px) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        @media (min-width: 992px) {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        @media (min-width: 1200px) {
            padding-top: 7px;
            padding-bottom: 7px;
        }
        @media (min-width: 1600px) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &__cont-solid-info {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon-register {
        margin-right: 10px;
        width: 18px;

        @media (min-width: 768px) {
            width: 20px;
            margin-right: 15px;
        }
        @media (min-width: 992px) {
            width: 22px;
        }
        @media (min-width: 1200px) {
            width: 24px;
        }
        @media (min-width: 1600px) {
            width: 26px;
        }
    }

    &__text-solid {
        font-family: Majorant-Lt;
        color: black;
        margin-bottom: 0;
        font-size: 12px;
        
        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            font-size: 0.75rem;
        }
        @media (min-width: 992px) {
            font-size: 0.8rem;
        }
        @media (min-width: 1200px) {
            font-size: 0.9rem;
        }
        @media (min-width: 1600px) {
            font-size: 1rem;
        }
    }

    &__cont-transparent {
        padding-bottom: 0rem;
        padding-top: 0rem;
        z-index: 10;
    }
    
    &__div {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        align-items: center;

        &--logo {
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
            display: flex;
            justify-content: start;
            align-items: center;

            @media (min-width: 768px) {
                flex: 0 0 19%;
                max-width: 19%;
            }
            @media (min-width: 992px) {
                flex: 0 0 17%;
                max-width: 17%;
            }
            @media (min-width: 1200px) {
                flex: 0 0 15%;
                max-width: 15%;
            }
            @media (min-width: 1600px) {
                flex: 0 0 13%;
                max-width: 13%;
            }
        }

        &--navigation {
            flex: 0 0 58.3333333333%;
            max-width: 58.3333333333%;

            @media (min-width: 768px) {
                flex: 0 0 81%;
                max-width: 81%;
            }
            @media (min-width: 992px) {
                flex: 0 0 83%;
                max-width: 83%;
            }
            @media (min-width: 1200px) {
                flex: 0 0 85%;
                max-width: 85%;
            }
            @media (min-width: 1600px) {
                flex: 0 0 87%;
                max-width: 87%;
            }
        }
    }

    &__logo {
        height: 22px;
        margin-bottom: 0px;
        
        @media (min-width: 576px) {
            height: 2.25rem;
        }
        @media (min-width: 768px) {
            height: 1.75rem;
        }
        @media (min-width: 992px) {
            height: 2.05rem;
        }
        @media (min-width: 1200px) {
            height: 2.3rem;
        }
        @media (min-width: 1600px) {
            height: 2.75rem;
        }
    }

    &__active-dropdown {
        color: white;
        text-align: right;
        font-size: 1rem;
        padding: 0.75rem 0;
        font-family: Majorant-Regular;
        
        @media (min-width: 576px) {
            font-size: 1.25rem;
        }
        @media (min-width: 768px) {
            display: none;
        }
    }

    &__menu {
        margin-bottom: 0;
        padding: 0px 0px;
        list-style: none;
    }
        
    &__menu-item {
        color: white;
        display: block;
        float: left;
        position: relative;
        font-family: Majorant-Lt;

        &:hover {
            cursor: pointer;
        }

        @media (min-width: 768px) {
            font-size: 0.95rem;
            margin: 0 0.95rem;
            padding: 0.84rem 0;
        }
        @media (min-width: 992px) {
            font-size: 1.1rem;
            margin: 0 1.1rem;
            padding: 1.1rem 0;
        }
        @media (min-width: 1200px) {
            font-size: 1.15rem;
            margin: 0 1.15rem;
            padding: 1.15rem 0;
        }
        @media (min-width: 1600px) {
            font-size: 1.5rem;
            margin: 0 1.5rem;
            padding: 1.5rem 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__menu-item-link {
        color: white;

        &:hover {
            color: white;
        }
    }

    &__menu-link {
        color: white;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }
        
        &:hover,
        &.active {
            color: white;

            &::after {
                width: 100%;
            }
        }
    }

    &__cont-navigation {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        display: none;
        align-items: center;
        justify-content: end;

        @media (min-width: 768px) {
            display: flex;
        }
    }

    &__menu-item-language {
        padding-left: 8px;
        padding-right: 8px;

        @media (min-width: 768px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &__menu-item-is-selected {
        color: #5AFF7A;
    }
}