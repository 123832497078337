.c-convert-currency {
    overflow: hidden;
    position: relative;
}

.keyart, .keyart_layer {
	height: 100vh;
}

#parallax {
	display: block;
}

.keyart {
	position: relative;
	z-index: 10;
}

.keyart_layer {
	width: 100%;
	position: absolute;
}
.keyart_layer.parallax {
	position: fixed;
}

// #keyart-0 {
//     background-image: url(../../assets/images/convert-currency/layer-01.svg);
// }
// #keyart-1 {
//     background-image: url(../../assets/images/convert-currency/layer-02.svg);
// }
// #keyart-2 {
//     background-image: url(../../assets/images/convert-currency/layer-03.svg);
// }
// #keyart-3 {
//     background-image: url(../../assets/images/convert-currency/layer-04.svg);
// }
// #keyart-4 {
//     background-image: url(../../assets/images/convert-currency/layer-05.svg);
// }
// #keyart-5 {
//     background-image: url(../../assets/images/convert-currency/layer-06.svg);
// }
// #keyart-6 {
//     background-image: url(../../assets/images/convert-currency/layer-07.svg);
// }
// #keyart-7 {
//     background-image: url(../../assets/images/convert-currency/layer-08.svg);
// }
#keyart-scrim {
    background-color: #ffaf00;
    opacity: 0;
}

.z-1 {
	z-index: 1;	
}
.z-2 {
	z-index: 2;	
}
.z-3 {
	z-index: 3;	
}
.z-4 {
	z-index: 4;	
}
.z-5 {
	z-index: 5;	
}
.z-6 {
	z-index: 6;	
}
.z-7 {
	z-index: 7;	
}
.z-8 {
	z-index: 8;	
}
.z-9 {
	z-index: 9;	
}
.z-10 {
	z-index: 10;	
}

// @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
//    	#keyart-0 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax0@2x.png');
// 		background-color: #ffaf1b;
// 	}
// 	#keyart-1 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax1@2x.png');
// 	}
// 	#keyart-2 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax2@2x.png');
// 	}
// 	#keyart-3 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax3@2x.png');
// 	}
// 	#keyart-4 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax4@2x.png');
// 	}
// 	#keyart-5 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax5@2x.png');
// 	}
// 	#keyart-6 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax6@2x.png');
// 	}
// 	#keyart-7 {
// 		background-image: url('http://www.firewatchgame.com/images/parallax/parallax7@2x.png');
// 	}
// }