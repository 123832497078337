.c-buttons {
    &__menu-dropdown {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
        display: grid;
        width: 100%;
    }

    &__menu-dropdown-plane,
    &__menu-dropdown-border {
        border-radius: 5px;
        font-size: 16px;
        text-decoration: none;
        color: #003936;
        display: inline-block;
        line-height: 1;
        padding: 9px 30px 12px;
        background: rgba(0, 0, 0, 0.1);
        transition: all 180ms;
        box-shadow: 0 3px 9px rgb(0 0 0 / 5%), inset 0 0 0 2px #00FD66;
        width: 180px;
        text-align: center;
        margin: 16px auto;

        &:hover {
            color: #003936;
            background: white;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__menu-dropdown-plane {
        color: white;
        background: linear-gradient(45deg,#00fd66,#00b466);
    }

    &__header-close-telegram {
        font-family: Majorant-Lt;
        color: #8e8e8e;
        padding-top: 0;
        padding-bottom: 0;
    }

    &--lineal-buttons {
        display: grid;
        justify-content: center;
    }

    &__header {
        display: inline-block;
        line-height: 1;
        border-radius: 5px;
        padding: 6px 30px 8px;
        color: white;
        background: rgba(0, 0, 0, 0.1);
        transition: all 180ms;
        box-shadow: 0 3px 9px rgb(0 0 0 / 5%), inset 0 0 0 2px #00FD66;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        min-width: 150px;
        
        @media (min-width: 768px) {
            font-size: 12px;
            min-width: 80px;
            padding: 5px 18px 7px;
        }
        @media (min-width: 992px) {
            min-width: 110px;
            padding: 7px 20px 9px;
        }
        @media (min-width: 1200px) {
            min-width: 112px;
        }
        @media (min-width: 1600px) {
            font-size: 14px;
            min-width: 142px;
        }
        
        &:hover {
            color: $black;
            background: white;
            box-shadow: 0 3px 9px rgb(0 0 0 / 5%), inset 0 0 0 2px #00B466;

            &:after {
                opacity: 0;
            }
        }

        &--whitepaper {
            margin-right: 4px;
        }
        
        &--register {
            margin-left: 4px;
            background: linear-gradient(45deg, #00FD66, #00B466);
        }
    }

    &__join-now {
        font-family: Majorant-Lt;
        border-radius: 5px;
        color: #000;
        text-decoration: none;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 2px;
        transition: 0.4s all ease;
        border: 0px solid #ff1806;
        margin-left: 0px;
        font-size: 12px;
        width: 200px;

        @media (min-width: 576px) {
            font-size: 0.8rem;
            width: 240px;
        }
        @media (min-width: 768px) {
            margin-left: 8px;
            border: 2px solid #ff1806;
            width: 150px;
            font-size: 0.75rem;
            margin-left: 12px;
        }
        @media (min-width: 1200px) {
            font-size: 0.8rem;
        }
        @media (min-width: 1600px) {
            font-size: 0.85rem;
        }

        &:active,
        &:hover {
            color: #000000;

            @media (min-width: 768px) {
                color: white;
                background: #ff1806;
                -webkit-box-shadow: 0px 0px 6px 0px rgba(255,24,6,1);
                -moz-box-shadow: 0px 0px 6px 0px rgba(255,24,6,1);
                box-shadow: 0px 0px 6px 0px rgba(255,24,6,1);
            }
        }
    }

    &__header-red {
        border-radius: 5px;
        border: 0;
        padding: 0;
        min-width: 150px;
        color: black;
        cursor: pointer;
        background: transparent;
        font-size: 12px;
        transition: all 0.4s ease;
        display: inline-block;
        position: relative;
        z-index: 1;
        text-align: center;
        text-decoration: none;

        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            padding: 4px 10px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 5px;
            padding: 2px;
            background: linear-gradient(45deg, #ff5106 , #ff1205 );
            -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            margin-left: 10px;

            @media (max-width: 768px) {
                padding: 0px;
            }
        }

        &:hover {
            color: $black;
            background: transparent;

            &:after {
                opacity: 0;
            }
        }

        &--whitepaper {
            margin-right: 4px;
        }
        
        &--register {
            margin-left: 4px;
            background: linear-gradient(45deg, #00FD66, #00B466);
        }
    }

    &__challenging {
        display: inline-block;
        font-family: Majorant-Lt;
        color: black;
        border-radius: 9999px;
        transition: all 200ms;
        background-color: #03fb66;
        padding: 0.5rem 4rem 0.5rem 4rem;
        font-size: 0.9rem;
        text-decoration: none;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid #03fb66;

        &:hover {
            color: $black;
            background: white;

            &:after {
                opacity: 0;
            }
        }

        @media (min-width: 576px) {
            padding: 0.5rem 6rem 0.5rem 6rem;
            font-size: 1rem;
        }
        @media (min-width: 768px) {
            padding: 0.5rem 6rem 0.5rem 6rem;
            font-size: 1.05rem;
        }
        @media (min-width: 992px) {
            padding: 0.5rem 6rem 0.5rem 6rem;
            font-size: 1.2rem;
        }
        @media (min-width: 1200px) {
            padding: 0.5rem 5.5rem 0.5rem 5.5rem;
            font-size: 1.35rem;
        }
        @media (min-width: 1600px) {
            padding: 0.5rem 6rem 0.5rem 6rem;
            font-size: 1.5rem;
        }
    }

    &__footer-receive {
        margin-left: 0px;
        border-radius: 5px;
        margin-bottom: 60px;
        padding: 0;
        background: transparent;
        width: 36px;
        height: 36px;
        margin-top: 16px;
        margin-bottom: 16px;

        @media (min-width: 576px) {
            width: 38px;
            height: 38px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        @media (min-width: 768px) {
            width: 32px;
            height: 32px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
        @media (min-width: 992px) {
            width: 28px;
            height: 28px;
            margin-top: 10px;
            margin-bottom: 9px;
        }
        @media (min-width: 1200px) {
            width: 46px;
            height: 46px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        @media (min-width: 1600px) {
            width: 48px;
            height: 48px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    &__documents {
        display: inline-block;
        line-height: 1;
        border-radius: 8px;
        color: white;
        background: rgba(0, 0, 0, 0.1);
        transition: all 180ms;
        box-shadow: 0 3px 9px rgb(0 0 0 / 5%), inset 0 0 0 2px #ff5106;
        text-decoration: none;
        text-align: center;
        padding: 6px 30px 8px;
        font-size: 18px;
        padding: 12px 5px 15px;
        min-width: 180px;
        margin-top: 36px;

        &--litepaper {
            margin-right: 4px;

            &:hover,
            &:active {
                color: #ff5106;
            }
        }

        &--whitepaper {
            margin-left: 4px;
            background: linear-gradient(45deg, #ff5106, #ff1205);

            &:hover,
            &:active {
                color: white;
            }
        }
        
        @media (min-width: 576px) {
            padding: 6px 30px 8px;
            font-size: 20px;
            padding: 13px 5px 16px;
            min-width: 200px;
            margin-top: 40px;
        }
        @media (min-width: 768px) {
            padding: 6px 30px 8px;
            font-size: 16px;
            padding: 12px 5px 15px;
            min-width: 190px;
            margin-top: 48px;
        }
        @media (min-width: 992px) {
            padding: 6px 30px 8px;
            font-size: 18px;
            padding: 13px 5px 16px;
            min-width: 220px;
            margin-top: 56px;
        }
        @media (min-width: 1200px) {
            padding: 6px 30px 8px;
            font-size: 20px;
            padding: 16px 5px 19px;
            min-width: 260px;
            margin-top: 64px;
        }
        @media (min-width: 1600px) {
            padding: 6px 30px 8px;
            font-size: 22px;
            padding: 17px 5px 20px;
            min-width: 280px;
            margin-top: 72px;
        }
    }

    &__view-tutorial {
        display: inline-block;
        line-height: 1;
        border-radius: 5px;
        color: #003936;
        background: #C4C4C4;
        border: 2px solid #C4C4C4;
        transition: all 180ms;
        text-decoration: none;
        text-align: center;
        padding: 11px 5px 14px;
        margin-top: 36px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        font-size: 18px;
        width: 180px;

        &:hover {
            border: 2px solid #003936;
            color: #003936;
        }
        
        @media (min-width: 576px) {
            font-size: 20px;
            width: 200px;
        }
        @media (min-width: 768px) {
            font-size: 18px;
            width: 180px;
        }
        @media (min-width: 992px) {
            font-size: 20px;
            width: 200px;
        }
        @media (min-width: 1200px) {
            font-size: 22px;
            width: 220px;
        }
        @media (min-width: 1600px) {
            font-size: 24px;
            width: 240px;
        }
    }

    &__register {
        color: white;
        border-radius: 10px;
        padding-right: 88px;
        background: rgba(255,81,6,1);
        background: -moz-linear-gradient(left, rgba(255,81,6,1) 0%, rgba(255,18,5,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,81,6,1)), color-stop(100%, rgba(255,18,5,1)));
        background: -webkit-linear-gradient(left, rgba(255,81,6,1) 0%, rgba(255,18,5,1) 100%);
        background: -o-linear-gradient(left, rgba(255,81,6,1) 0%, rgba(255,18,5,1) 100%);
        background: -ms-linear-gradient(left, rgba(255,81,6,1) 0%, rgba(255,18,5,1) 100%);
        background: linear-gradient(to right, rgba(255,81,6,1) 0%, rgba(255,18,5,1) 100%);
        padding-bottom: 8px;
        font-size: 15px;
        padding-left: 58px;
        padding-right: 58px;
        margin-top: 16px;

        @media (min-width: 576px) {
            font-size: 16px;
            padding-left: 63px;
            padding-right: 63px;
            margin-top: 20px;
        }
        @media (min-width: 768px) {
            font-size: 19px;
            padding-left: 68px;
            padding-right: 68px;
            margin-top: 48px;
            border-radius: 5px;
        }
        @media (min-width: 992px) {
            font-size: 22px;
            padding-left: 73px;
            padding-right: 73px;
            margin-top: 52px;
        }
        @media (min-width: 1200px) {
            font-size: 25px;
            padding-left: 78px;
            padding-right: 78px;
            margin-top: 56px;
        }
        @media (min-width: 1600px) {
            font-size: 28px;
            padding-left: 83px;
            padding-right: 83px;
            margin-top: 60px;
        }
    }
}